<template>
    <div>
        <div id="header" class="fxwidth">
            <div class="hdback" @click="goBack"></div>
            <div class="hdtitle">消息通知</div>
        </div>
            <div class="headerhd"></div>

        <div class="skeletonbx" v-if="list.length === 0 || init === true">
            <van-skeleton row="5"></van-skeleton>
        </div>

<div class="wrap">
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
        <van-list v-model="loadmore"
                  :immediate-check="false"
                  :finished="finished"
                  finished-text="没有了"
                  :error.sync="error"
                  :error-text="list.length>0?'读取失败':''"
                  @load="loadMore">
            <ul class="notice_list" v-if="list.length > 0">
                <li v-for="(c,i) in list" :key="i">
                    <div class="title">{{c.title}}<span class="time">{{c.create_time}}</span></div>
                    <router-link to="/my/cost">
                        <div class="content" v-html="c.content"></div>
                    </router-link>
                </li>
            </ul>
        </van-list>
    </van-pull-refresh>

</div>

    </div>
</template>

<script>

    export default {
        name: "notice",
        data() {
            return {
                list: [],
                init: true,
                loadmore: false, //是否在加载更多，当滚动到底部时自动设置为true(不需手动设置)，每次加载完都得手动设置为false，
                loadmore_error: false,
                finished: false, //是否加载完毕
                isloading: false, //是否正在读取
                error: false,     //是否读取错误，有错误自动设置为true，点击又重新读取更多（自动设置为false）
                refreshing: false,  //是否在下拉刷新
                pageIndex: 1,
                formPath: ''
            }
        },
        activated() {
            this.onRefresh()
        },
        methods: {
            loadMore() {
                let that = this
                if(!that.loadmore_error)
                    that.pageIndex++
                that.getList()
            },
            onRefresh(){
                let that = this
                this.pageIndex = 1
                this.finished = false
                this.loadmore = false
                this.error = false
                that.getList()
            },
            getList(){
                let that = this
                that.loadmore_error = false
                // console.log(that.pageIndex)
                that.$post(that.$api.mynotice, {page: that.pageIndex}).then(function (res) {
                    // console.log(res.data)
                    let tmplist = res.data.notice_list
                    if(tmplist.length>0) {
                        if(that.refreshing)
                            that.list = tmplist  //如果是下拉刷新则列表重置
                        else
                            that.list = that.list.concat(tmplist)
                        if (res.data.end)
                            that.finished = true
                        that.getMatchInfo()
                    }
                    else{
                        that.finished = true
                    }
                }).catch(function () {
                    that.$toast.fail('读取失败')
                    that.error = true
                    that.loadmore_error = true
                }).finally(function () {
                    // console.log('finally')
                    that.init = false
                    that.loadmore = false
                    that.isloading = false
                    that.refreshing = false
                })
            },
            goBack(){
                //let that = this
                this.$router.push({
                    path: "/my" //that.formPath === '' ?'/':that.formPath
                })
            },
            getMatchInfo(){
                //console.log(that.$refs.sdtui)
                //let that = this
                    //let line = that.$refs.sdtui
                    //console.log(line)
                    // line.forEach((item) => {
                    //     console.log(item)
                    // })


            }
        },
        beforeRouteEnter(to, form, next){
            next(vm => {
                vm.formPath = form.path
            })
        },
        mounted(){
            //console.log(this.$refs.sdtui)
        }
    }
</script>

<style scoped>
.notice_list{}
.notice_list li{overflow: hidden;background: #fff;margin-bottom: 10px;}
.notice_list .title{padding: 0 15px;line-height: 35px;border-bottom: solid 1px #eee;font-size:13px;color:#666;}
.notice_list .title span{float:right;color:#999;}
.notice_list .content{overflow: hidden;padding:10px 15px;color:#444;font-size:14px;line-height: 22px;}
.notice_list .content >>> span{color:#09f;padding:0 2px;}
.notice_list .content >>> b{color:#f00;}
/*.notice_list li.high .content{color:#000;}*/
</style>