<template>
    <div id="gzbox">
        <div id="header" class="fxwidth">
            <div class="hdback" @click="goBack"></div>
            <div class="hdtitle">发布规则及奖励</div>
        </div>
        <div class="headerhd"></div>

        <div class="rule">
            <p>1. 注册会员即可发布推介；</p>
            <p>2. 每天最多可发布10场推介；</p>
            <p>3. 每发布一场推介可获得10积分奖励；</p>
            <p>4. 发布的推介赢球即可获得20积分奖励；</p>
        </div>
        <div class="rule">
            <p class="sm">金豆数量说明：</p>
            <p>订阅所需金豆数量根据您的15天胜率进行计算，具体规则如下：</p>
            <p>  胜率百分之50以下    数量0-50个</p>
            <p> 胜率百分之51到60的  数量0-100个</p>
            <p>胜率百分之61到70的  数量0-500个</p>
            <p>  胜率百分之71到80的  数量0-1000个</p>
            <p>  胜率百分之81到90的  数量0-2000个</p>
            <p>  胜率百分之91到100的 数量0-3000个</p>
        </div>

    </div>
</template>

<script>
    export default {
        name: "rule",
        data() {
            return {
                formPath: ''
            }
        },
        created() {

        },
        methods: {
            goBack(){
                let that = this
                this.$router.push({
                    path: that.formPath === '' ?'/':that.formPath
                })
            }
        },
        beforeRouteEnter(to, form, next){
            next(vm => {
                vm.formPath = form.path
            })
        }
    }
</script>

<style scoped>
    .rule{padding: 20px;overflow: hidden;text-align: left;font-size:14px;line-height: 28px;color:#666;background-color: #fff;margin:.6rem;}
    .rule .sm{margin:0 0 10px 0;color:#000;}
</style>