<template>
    <div>
        <div id="header" class="fxwidth">
            <div class="hdback" @click="$router.back()"></div>
            <div class="hdtitle">意见反馈</div>
        </div>
            <div class="headerhd"></div>


    </div>
</template>

<script>
    export default {
        name: "help",
        data() {
            return {

            }
        },
        created() {

        },
        methods: {

        }
    }
</script>

<style scoped>
</style>