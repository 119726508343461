<template>
    <div>
        <div id="header" class="fxwidth">
            <div class="hdback" @click="goBack"></div>
            <div class="hdtitle">帮助中心</div>
        </div>
            <div class="headerhd"></div>

        <div class="helpbox">

            <div class="item">
                <div class="title">关于</div>
                <div>
                    推球人属于赛事推介平台，我们的宗旨是为所有推介爱好者提供精彩公平的推介内容，所有推介半场公开，凭实力推荐。
                </div>
            </div>
            <div class="item">
                <div class="title">金豆与积分</div>
                <p>1、100积分=1金豆=1元</p>
                <p>2、金豆可以充值或者发布赛事有订阅并命中即获得。</p>
                <p>3、积分可以签到或者发布赛事获得 ，积分可以随时转换为金豆。</p>
                <p>4、金豆可以随时提现但需扣除百分十的手续（提现3天内到帐）</p>
                <p>5、金豆只可用于订阅本站专家方案，不能用于购买彩票，购买彩票请到线下或者线上竞彩店</p>
            </div>

            <div class="item">
                <div class="title">专家方案</div>
                <p>1、本站属于推介平台，每们会员都可以发布或订阅推介方案，每场推介都是半场公开，杜绝作假，大家凭实力。</p>
                <p>2、会员发布的方案，如有人订阅并命中，即获得所设置的7成金豆。</p>
                <p>3、会员订阅的方案如不中即退还所有金豆。</p>
                <p>4、关注专家不迷路，导航栏的排行榜以及会员中心有每位专家的胜率明细可供参考，都是真实成绩。</p>
            </div>

            <div class="item">
                <div class="title">规则及奖励</div>
                <p>1、注册会员即可发布推介；</p>
                <p>2、每天最多可发布10场推介；</p>
                <p>3、每发布一场推介可获得10积分奖励；</p>
                <p>4、发布的推介赢球即可获得20积分奖励；</p>
                <div class="tips">
                <p class="sm">金豆数量说明：</p>
                <p>订阅所需金豆数量根据您的15天胜率进行计算，具体如下</p>
                <p>  胜率百分之50以下    数量0-50个</p>
                <p> 胜率百分之51到60的  数量0-100个</p>
                <p>胜率百分之61到70的  数量0-500个</p>
                <p>  胜率百分之71到80的  数量0-1000个</p>
                <p>  胜率百分之81到90的  数量0-2000个</p>
                <p>  胜率百分之91到100的 数量0-3000个</p>
                </div>
            </div>

        </div>

    </div>
</template>

<script>
    export default {
        name: "help",
        data() {
            return {
                formPath: ''
            }
        },
        created() {

        },
        methods: {
            goBack(){
                let that = this
                this.$router.push({
                    path: that.formPath === '' ?'/':that.formPath
                })
            }
        },
        beforeRouteEnter(to, form, next){
            next(vm => {
                vm.formPath = form.path
            })
        }
    }
</script>

<style scoped>
</style>