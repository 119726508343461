<template>
    <div>
        <div id="header" class="fxwidth">
            <div class="logo"></div>
            <div class="tpnav tpsign" v-if="isSign">已签到</div>
            <div class="tpnav tpsign" @click="toSign" v-else>签到得金豆</div>
            <div class="tpnav tpreg"><router-link to="/register">注册</router-link></div>
            <div class="tpnav tpsearch" @click="toShowSearch"></div>
        </div>
        <div class="headerhd"></div>
        <div class="searchpop" v-if="showSearch">
            <div class="bg" @click="toShowSearch"></div>
            <div class="bx"><input type="text" placeholder="输入关键词" onfocus="true" v-model="keys" ref="refkeys" @keyup.enter="toSearch" /><button @click="toSearch"></button></div>
        </div>

        <van-swipe class="banner" indicator-color="white">
            <van-swipe-item><div><img src="../assets/images/banner2.png"></div></van-swipe-item>
        </van-swipe>

        <div class="skeletonbx" v-if="userlist.length === 0">
            <van-skeleton row="8" title></van-skeleton>
        </div>
        <div class="hotexpert" v-if="userlist.length > 0">
            <div class="hometab"><span class="curr">热门专家</span><router-link to="/member">更多+</router-link></div>
            <ul>
                <li v-for="(c,i) in userlist" :key="i">
                    <router-link :to="'/member/'+c.id">
                    <div class="img">
                        <img :src="c.portrait" v-if="c.portrait" />
                        <img :src="defimg" v-else/>
                    </div>
                    <div class="name">{{c.name}}</div>
                    </router-link>
                </li>
            </ul>
        </div>

        <div class="links linkkeys" v-if="linkkeys_list.length>0">
            <div class="con">
                <a target="_blank" v-for="(c,i) in linkkeys_list" :key="i" :href="c.url">{{c.name}}</a>
            </div>
        </div>

        <div class="everydan" v-if="dan_list.length > 0">
            <div class="hometab"><span class="curr">每日精选单场</span><router-link to="/news">更多+</router-link></div>
            <div class="danlist">
                <div class="item" v-for="(c, i) in dan_list" :key="i"><div><router-link :to="/news/+c.id">{{c.title}}</router-link><span>{{c.time}}</span></div></div>
            </div>
        </div>

        <div class="hometab hottab" v-if="football_list.length > 0 || basketball_list.length > 0">
            <span :class="balltab==1?'curr':''" @click="changetab(1)">足球推介</span>
            <span :class="balltab==2?'curr':''" @click="changetab(2)">篮球推介</span>
            <router-link to="/recommend" v-if="balltab==1">更多+</router-link>
            <router-link to="/recommend?b=2" v-else>更多+</router-link>
        </div>

        <van-list v-model="football_loadmore"  v-if="balltab === 1"
                  :immediate-check="false"
                  :finished="football_finished"
                  :error.sync="football_error"
                  :error-text="football_list.length>0?'读取失败':''"
                  @load="getMore">
            <recommend_mini :list="football_list" :type="0"/>
        </van-list>

        <van-list v-model="basketball_loadmore"  v-if="balltab === 2"
                  :immediate-check="false"
                  :finished="basketball_finished"
                  :error.sync="basketball_error"
                  :error-text="basketball_list.length>0?'读取失败':''"
                  @load="getMore">
        <recommend_mini :list="basketball_list" :type="0"/>
        </van-list>

        <div class="links" v-if="links_list.length>0">
            <div class="hometab">
                <span class="curr">友情链接</span>
            </div>
            <div class="con">
                <a target="_blank" v-for="(c,i) in links_list" :key="i" :href="c.url">{{c.name}}</a>
            </div>
        </div>

    </div>
</template>

<script>
    import defPortrait from "../assets/images/user.png"
    import recommend_mini from "./public/recommend_mini"
    export default {
        name: "index",
        components:{
          recommend_mini
        },
        data() {
            return {
                football_list: [],
                basketball_list: [],
                userlist: [],
                balltab: 1,
                defimg: defPortrait,
                isSign: false,
                links_list: [],
                linkkeys_list: [],
                showSearch: false,
                keys: '',

                football_page: 1,
                football_error: false,
                football_loadmore: false,
                football_finished: false,
                basketball_page: 1,
                basketball_error: false,
                basketball_loadmore: false,
                basketball_finished: false,

                dan_list: []
            }
        },
        created() {
            this.getList()
        },
        methods: {
            toShowSearch(){
                this.$router.replace({path: '/search'})
                // if(this.showSearch)
                //     this.showSearch = false
                // else
                //     this.showSearch = true
            },
            toSearch(){
                let that = this
                if(that.keys === ''){
                    this.$nextTick(() =>{
                        this.$refs.refkeys.focus()
                    })
                    return
                }
                that.$router.replace({path: '/search?key='+that.keys})
                this.showSearch = false
                this.keys = ''
            },
            changetab(i){
              this.balltab = i
            },
            getList(){
                let that = this
                that.$post(that.$api.home, {page: that.pageIndex}).then(function (res) {
                    //console.log(res)
                    that.loading = false

                    let notice = res.data.notice_count
                    that.$store.commit('SET_NOTICE', notice)

                    let userlist = res.data.user_list
                    if(userlist.length > 0) {
                        userlist.forEach(function (i) {
                            if(i.portrait)
                                i.portrait = that.$api.imgBaseUrl + i.portrait
                        })
                        that.userlist = userlist
                    }

                    let sign0 = res.data.isSign
                    that.isSign = sign0

                    let list0 = res.data.football_list
                    if(list0.length>0) {
                        list0.forEach(function (i) {
                            if(i.user.portrait)
                                i.user.portrait = that.$api.imgBaseUrl + i.user.portrait
                        })
                        that.football_list = list0
                    }

                    let list1 = res.data.basketball_list
                    if(list1.length>0) {
                        list1.forEach(function (i) {
                            if(i.user.portrait)
                                i.user.portrait = that.$api.imgBaseUrl + i.user.portrait
                        })
                        that.basketball_list = list1
                    }

                    that.links_list = res.data.links
                    that.linkkeys_list = res.data.linkkeys

                    if(res.data.dan_list){
                        that.dan_list = res.data.dan_list
                    }
                })
            },
            toSign: function () {
                if (this.isSign)
                    return
                let token = this.$store.state.token, that = this
                if(token === null || token === '' || token === undefined){
                    //this.$toast({message: '请登录'})
                    that.$store.commit('SHOW_LOGINPOP')
                }
                else {
                    this.$toast.loading()
                    this.$post(that.$api.sign).then(function (res) {
                        if(res.code === 1){
                            that.$toast.success(res.msg)
                            that.isSign = true
                        }
                        else {
                            that.$toast.fail(res.msg)
                        }
                    })
                }
            },

            getMore(){
                let that = this
                // if(that.football_page>=10 || that.basketball_page >= 10){
                //     if(that.balltab === 1)
                //         that.football_finished = true
                //     else
                //         that.basketball_finished = true
                //     return
                // }
                let pageIndex = 2
                if(that.balltab === 1) {
                    if(!that.football_error)
                        that.football_page++
                    pageIndex = that.football_page
                    that.football_error = false
                    that.basketball_loadmore = false
                }
                else{
                    if(!that.basketball_error)
                        that.basketball_page++
                    pageIndex = that.basketball_page
                    that.basketball_error = false
                    that.football_loadmore = false
                }
                // console.log(pageIndex)
                that.$post(that.$api.recommend_more, {page: pageIndex, type: that.balltab})
                    .then(function (res) {
                        if(res.code === 1) {
                            let tmplist = res.data.recommend_list
                            if (tmplist.length > 0) {
                                tmplist.forEach(function (i) {
                                    if (i.user.portrait)
                                        i.user.portrait = that.$api.imgBaseUrl + i.user.portrait
                                })
                                if(that.balltab === 1)
                                    that.football_list = that.football_list.concat(tmplist)
                                else
                                    that.basketball_list = that.basketball_list.concat(tmplist)
                                if (res.data.end) {
                                    if(that.balltab === 1)
                                        that.football_finished = true
                                    else
                                        that.basketball_finished = true
                                    // console.log('end')
                                }
                            } else {
                                if(that.balltab === 1)
                                    that.football_finished = true
                                else
                                    that.basketball_finished = true
                                // console.log('no data')
                            }
                        }else{
                            if(that.balltab === 1)
                                that.football_finished = true
                            else
                                that.basketball_finished = true
                            // console.log('request fail~')
                        }
                    }).catch(function () {
                        that.$toast.fail('读取失败')
                        if(that.balltab === 1)
                            that.football_error = true
                        else
                            that.basketball_error = true
                        // console.log('catch')
                    }).finally(function () {
                        if(that.balltab === 1)
                            that.football_loadmore = false
                        else
                            that.basketball_loadmore = false
                        // console.log('finally')
                    })
            },
        }
    }
</script>

<style scoped>
</style>
