<template>
    <!--发布推介弹窗页面-->
    <div>
        <van-popup position="bottom" v-model="showPopup" @click-overlay='close' :duration=".3">
            <div class="popbox matchpopbox" v-if="match">
                <div class="popboxtitle">发布推介<p>请选择主客队、大小球，金豆数量进行发布</p><div class="close" @click="close"></div></div>
                <div class="popboxcon">
                    <div class="sai">
                        <span :style="'color:#fff;background-color:'+match.color">{{match.matchtype}}</span>
                        {{match.time}}
                    </div>
                    <div class="duiz">
                        <span class="sp">{{match.shangpan}}</span>
                        <em class="vs">vs</em>
                        <span class="xp">{{match.xiapan}}</span>
                    </div>
                    <div class="selcm">
                        <span :class="{curr:selectType===1, zd:true}" @click="selectCommend(1)">主队</span>
                        <em>{{match.pankou}}<i>让球</i></em>
                        <span :class="{curr:selectType===2, zd:true}" @click="selectCommend(2)">客队</span>
                    </div>
                    <div class="selcm">
                        <span :class="{curr:selectType===3, dxq:true}" @click="selectCommend(3)">大球</span>
                        <em>{{match.dxp}}<i>大小球</i></em>
                        <span :class="{curr:selectType===4, dxq:true}" @click="selectCommend(4)">小球</span>
                    </div>
                    <div class="gold">订阅金豆：
                        <div class="putbx">
                            <div @click="jisuan(0)" class="ji">-</div>
                            <input placeholder="输入数字" :min="gold_min" :max="gold_max" type="number" v-model="selectGold"/>
                            <div @click="jisuan(1)">+</div>
                        </div>
                        <em>个 （{{gold_min}}-{{gold_max}}）</em>
<!--                        <van-icon name="question-o"></van-icon>-->
                    </div>
                    <div class="desc" v-if="isSpeak">
    <!--                    <div class="liu">推介分析</div>-->
                        <div class="con">
                            <textarea v-model="selectDesc" placeholder="推介分析，300字以内，可留空"></textarea>
<!--                            <div v-else>系统已禁言，不可发布分析</div>-->
                        </div>
                    </div>
                    <button type="button" @click="toPublish" class="butn">确定</button>
                </div>
            </div>
            <div v-else class="matchpopload">
                <van-skeleton row="3"></van-skeleton>
            </div>
        </van-popup>

    </div>
</template>

<script>
    export default {
        name: "publish",
        data() {
            return {
                mid: 0,
                match: null,
                showPopup: false,
                selectType: 0,
                selectGold: '',
                selectIndex: 0,
                selectDesc: '',
                gold_min: 0,
                gold_max: 0,
                isSpeak: true
            }
        },
        created() {
            let that = this
            that.mid = that.$parent.$data.selectMatchId
            that.showPopup = that.$parent.$data.showPublishPopup
            if (that.mid != 0 && that.mid != undefined) {
                that.$post(that.$api.match_get, {mid: that.mid}).then(function (res) {
                    // console.log(res)
                    if (res.code === 1) {
                        var match = res.data.match
                        var gold_min = res.data.gold_min
                        var gold_max = res.data.gold_max
                        that.match = match
                        that.gold_min = gold_min
                        that.gold_max = gold_max
                        that.isSpeak = res.data.isSpeak
                    } else {
                        that.close()
                        that.$toast.fail(res.msg)
                    }
                })
            }
        },
        methods: {
            jisuan(type){
                let that = this
                if(that.selectGold === ''){
                    that.selectGold = 1
                    return
                }
                if(type === 0){
                    if(that.selectGold === that.gold_min){
                        that.selectGold = that.gold_min
                    }
                    else{
                        that.selectGold -= 1
                    }
                }
                else{
                    if(that.selectGold >= that.gold_max)
                        that.selectGold = that.gold_max
                    else
                        that.selectGold += 1
                }
            },
            close(){
                let that = this
                that.showPopup = false
                that.$parent.closePublishPopBox()
            },
            selectCommend(type){
                this.selectType = type
            },
            toPublish(){
                let that = this, type = this.selectType, gold=this.selectGold, desc = this.selectDesc
                if(type === 0) {
                    that.$toast('请选择主客队或大小球')
                    return
                }
                if(gold === '') {
                    that.$toast('请输入金豆数量')
                    return
                }
                if(gold < that.gold_min || gold > that.gold_max ) {
                    that.$toast('金豆数量'+that.gold_min+'到'+that.gold_max+'之间')
                    return
                }
                if(that.isSpeak && desc.length > 300) {
                    that.$toast('分析内容长度不得大于300')
                    return
                }
                that.$toast.loading({message: '发布中', duration: 0})
                that.$post(that.$api.match_publish, {mid: that.mid, gold: that.selectGold, type: that.selectType, desc: desc}).then(function (res) {
                    if(res.code === 1){
                        that.close()
                        that.$parent.publishSuccess()
                        that.$toast.success(res.msg)
                        // console.log(res)
                    }
                    else{
                        that.$toast.fail(res.msg)
                    }
                }).catch(function () {
                    that.$toast.fail('fail')
                })
            },
        }
    }
</script>

<style scoped>

</style>
