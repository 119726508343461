<template>
    <div>
        <div id="header" class="fxwidth">
            <div class="hdback" @click="goBack"></div>
            <div class="hdtitle">我的关注</div>
        </div>
        <div class="headerhd"></div>

        <div class="skeletonbx" v-if="list.length === 0 || init === true">
            <van-skeleton row="5"></van-skeleton>
        </div>
        <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
        <van-list v-model="loadmore"
                  :immediate-check="false"
                  :finished="finished"
                  finished-text="没有了"
                  :error.sync="error"
                  :error-text="list.length>0?'读取失败':''"
                  @load="loadMore">
            <ul class="memberlist" v-if="list.length > 0">
                <li v-for="(c,i) in list" :key="i">
                    <router-link :to="'/member/'+c.id">
                        <div class="img">
                            <img :src="c.portrait" :onerror="defimg" v-if="c.portrait">
                            <img :src="defimg" v-else>
                        </div>
                        <div class="cone">
                            <div class="name">{{c.name}}</div>
                            <div class="sl">近期胜率<b>{{c.winrate}}%</b></div>
                            <div class="intro intro2" v-if="c.intro">{{c.intro}}</div>
                        </div>
                    </router-link>
                    <div class="rel">
                        <span v-if="c.relation == 0" @click="addFollow(i)"><em>+</em>关注</span>
                        <span v-else-if="c.relation == 1" class="en" @click="unAddFollow(i)">已关注</span>
                        <span v-else-if="c.relation == 2" class="en" @click="unAddFollow(i)">互相关注</span>
                    </div>
                </li>
            </ul>
        </van-list>
        </van-pull-refresh>

    </div>
</template>

<script>
    import defPortrait from "../../assets/images/user.png"
    export default {
        name: "member",
        data() {
            return {
                uid: null,
                list: [],
                init: true,
                loadmore: false, //是否在加载更多，当滚动到底部时自动设置为true(不需手动设置)，每次加载完都得手动设置为false，
                loadmore_error: false,
                finished: false, //是否加载完毕
                isloading: false, //是否正在读取
                error: false,     //是否读取错误，有错误自动设置为true，点击又重新读取更多（自动设置为false）
                refreshing: false,  //是否在下拉刷新
                pageIndex: 1,
                defimg: defPortrait,
                formPath: ''
            }
        },
        created() {
            this.list = []
            this.onRefresh()
        },
        methods: {
            loadMore() {
                let that = this
                if(!that.loadmore_error)
                    that.pageIndex++
                that.getList()
            },
            onRefresh(){
                let that = this
                this.pageIndex = 1
                this.finished = false
                this.loadmore = false
                this.error = false
                that.getList()
            },
            getList(){
                let that = this
                that.loadmore_error = false
                that.$post(that.$api.myfollow,{page: that.pageIndex}).then(function (res) {
                    //console.log(res)
                    let tmplist = res.data.list
                    if(tmplist.length>0) {
                        tmplist.forEach(function (i) {
                            if(i.portrait)
                                i.portrait = that.$api.imgBaseUrl + i.portrait
                        })
                        if(that.refreshing)
                            that.list = tmplist  //如果是下拉刷新则列表重置
                        else
                            that.list = that.list.concat(tmplist)
                        if (res.data.end)
                            that.finished = true
                    }
                    else{
                        that.finished = true
                    }
                }).catch(function () {
                    that.$toast.fail('读取失败')
                    that.error = true
                    that.loadmore_error = true
                }).finally(function () {
                    // console.log('finally')
                    that.init = false
                    that.loadmore = false
                    that.isloading = false
                    that.refreshing = false
                })
            },
            addFollow(index){
                let that = this
                let id = that.list[index].id
                that.$toast.loading()
                that.$post(that.$api.addfollow, {mid: id}).then(function (res) {
                    if(res.code === 1){
                        that.$toast.success(res.msg)
                        that.list[index].relation = res.data.relation
                    }
                    else{
                        that.$toast(res.msg)
                    }
                })
            },
            unAddFollow(index){
                let that = this
                let id = that.list[index].id
                that.$toast.loading()
                that.$post(that.$api.unaddfollow, {mid: id}).then(function (res) {
                    if(res.code === 1){
                        that.$toast.success(res.msg)
                        that.list[index].relation = res.data.relation
                    }
                    else{
                        that.$toast(res.msg)
                    }
                })
            },
            goBack(){
                //let that = this
                this.$router.push({
                    path: "/my" //that.formPath === '' ?'/':that.formPath
                })
            }
        },
        beforeRouteEnter(to, form, next){
            next(vm => {
                vm.formPath = form.path
            })
        }
    }
</script>

<style scoped>
</style>