/*
公共方法
 */

export function formatTime(timeStr, type){
    var tsplist = timeStr.split('T')
    var dates = tsplist[0]
    var times = tsplist[1]
    var year = dates.split('-')[0]
    var month = dates.split('-')[1]
    var day = dates.split('-')[2]
    var hour = times.split(':')[0]
    var min = times.split(':')[1]
    //console.log(year)
    if(type === 1)
        return month + '-' + day + ' ' + hour + ':' + min
    return year + '-' + month + '-' + day + ' ' + hour + ':' + min
}