<template>
    <div>
        <div id="header" class="fxwidth">
            <div class="hdtitle">推介</div>
            <div class="hdbtn hdpush" @click="toPublish">我要推介</div>
            <div class="tpnav tpsearch tpsearch2" @click="toSearch"></div>
        </div>
            <div class="headerhd headerhd_lg"></div>

        <div class="fxwidth navtab">
            <span v-for="(item, index) in tabList" :key="index"
                  :class="{curr:tabIndex===index}"
                  @click="changeTab(index)">{{item}}<i v-if="index==0"></i></span>
        </div>

<!--        <van-tabs v-model="tabIndex">-->
<!--            <van-tab title="标签 1">内容 1</van-tab>-->
<!--            <van-tab title="标签 2">内容 2</van-tab>-->
<!--        </van-tabs>-->

        <div class="skeletonbx" v-if="init === true">
            <van-skeleton row="5"></van-skeleton>
        </div>

        <div v-if="list.length === 0 && init === false"  class="list-none">没有数据~</div>
        <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
        <van-list v-model="loadmore"
                  :immediate-check="false"
                  :finished="finished"
                  :finished-text="list.length>0?'没有了':''"
                  :error.sync="error"
                  :error-text="list.length>0?'读取失败':''"
                  @load="loadMore">
            <recommend_mini :list="list" v-if="list.length>0"/>
        </van-list>
        </van-pull-refresh>

    </div>
</template>

<script>
    import defPortrait from "../assets/images/user.png"
    import recommend_mini from "./public/recommend_mini"
    export default {
        name: "recommend",
        components: {
          recommend_mini
        },
        data() {
            return {
                list: [],
                init: true,
                loadmore: false, //是否在加载更多，当滚动到底部时自动设置为true(不需手动设置)，每次加载完都得手动设置为false，
                loadmore_error: false,
                finished: false, //是否加载完毕
                error: false,     //是否读取错误，有错误自动设置为true，点击又重新读取更多（自动设置为false）
                refreshing: false,  //是否在下拉刷新
                pageIndex: 1,
                defimg: defPortrait,
                lastId: 0,  //列表最后一条数据的id
                tabList: ['足球', '篮球'],
                tabIndex: 0,
                dataType: [1, 2]
            }
        },
        created() {
            this.getList()
        },
        methods: {
            toSearch(){
                this.$router.replace({path: '/search'})
            },
            loadMore() {
                let that = this
                if(!that.loadmore_error)
                    that.pageIndex++
                that.getList()
            },
            changeTab(index){
                if (this.tabIndex === index)
                    return
                this.tabIndex = index
                this.list = []
                this.pageIndex = 1
                this.init = true
                this.error = false
                this.finished = false
                this.loadmore = false
                this.lastId = 0
                this.getList()
            },
            onRefresh(){
                let that = this
                this.lastId = 0
                this.pageIndex = 1
                this.finished = false
                this.loadmore = false
                this.error = false
                that.getList()
            },
            getLastID(){
                let that = this
                if(that.list.length>0){
                    this.lastId = that.list[that.list.length-1].id
                }
                else
                    this.lastId = 0
            },
            getList(){
                let that = this
                that.loadmore_error = false
                that.getLastID()
                that.$post(that.$api.recommend_list,
                    {page: that.pageIndex, lastid: that.lastId, type: that.dataType[that.tabIndex]})
                    .then(function (res) {
                    //console.log(res.data)
                        if(res.code === 1) {
                            let tmplist = res.data.recommend_list
                            if (tmplist.length > 0) {
                                tmplist.forEach(function (i) {
                                    if (i.user.portrait)
                                        i.user.portrait = that.$api.imgBaseUrl + i.user.portrait
                                })
                                if (that.refreshing)
                                    that.list = tmplist  //如果是下拉刷新则列表重置
                                else
                                    that.list = that.list.concat(tmplist)
                                if (res.data.end)
                                    that.finished = true
                            } else {
                                that.finished = true
                            }
                        }else{
                            that.finished = true
                        }
                }).catch(function () {
                    that.$toast.fail('读取失败')
                    that.error = true
                    that.loadmore_error = true
                }).finally(function () {
                    that.init = false
                    that.loadmore = false
                    that.refreshing = false
                })
            },
            toPublish(){
                this.$router.push('/match')
            }
        }
    }
</script>

<style scoped>
</style>
