<template>
    <div>
        <div id="header" class="fxwidth">
            <div class="hdback" @click="goBack"></div>
            <div class="hdtitle">提现兑换</div>
        </div>
            <div class="headerhd headerhd_lg"></div>

        <div class="fxwidth navtab">
            <span v-for="(item, index) in tabList" :key="index"
                  :class="{curr:tabIndex===index}"
                  @click="changeTab(index)">{{item}}<i v-if="index==0"></i></span>
        </div>

<div class="wrap">
    <div class="tixian" v-if="tabIndex===0">
        <div class="tips" v-if="tixian_record"><van-icon name="question"></van-icon><span>您有{{tixian_record}}笔提现申请正在处理中...</span></div>
        <div class="name">提现金额<span>当前金豆 {{gold}}，可提现<em>{{tixian_ke}}</em>元</span></div>
        <div class="put"><input type="number" placeholder="请输入金额" :max="tixian_ke" v-model="tixian_value"><span>元</span></div>
        <button type="button" @click="onTixian">提交申请</button>
        <div class="link"><router-link to="tixian/list">查看提现记录</router-link></div>
    </div>
    <div class="tixian duihuan" v-else>
        <div class="name">兑换金豆<span>当前积分 {{integral}}，可兑换<em>{{integral_ke}}</em>金豆</span></div>
        <div class="put"><input type="number" placeholder="请输入兑换数量" :max="integral_ke" v-model="rechange_value"><span>金豆</span></div>
        <button type="button" @click="onRechange">立即兑换</button>
        <div class="text">
            <div class="gz">兑换规则</div>
            <p>每100积分可兑换1金豆</p>
        </div>
    </div>
</div>

    </div>
</template>

<script>
    export default {
        name: "tixian",
        data() {
            return {
                showPopup: false,
                tabList: ['提现', '兑换'],
                tabIndex: 0,
                gold: 0,
                tixian_ke: 0,
                integral_ke: 0,
                integral: 0,
                tixian_record: 0,
                tixian_value: '',
                rechange_value: '',
            }
        },
        activated() {
            this.getData()
            if(this.$route.query.t){
                let t = this.$route.query.t
                if(t === '2'){
                    this.tabIndex = 1
                }
                else
                    this.tabIndex = 0
            }
            else
                this.tabIndex = 0
        },
        methods: {
            changeTab(index){
                if (this.tabIndex === index)
                    return
                this.tabIndex = index
            },
            getData(){
                let that = this
                that.$post(that.$api.mywithdraw).then(function (res) {
                    // console.log(res)
                    if(res.code === 1){
                        let withdraw = res.data.withdraw
                        let integral0 = res.data.exchange
                        that.gold = withdraw.gold
                        that.tixian_ke = withdraw.tixian_ke
                        that.tixian_record = withdraw.tixian_record
                        that.integral = integral0.integral
                        that.integral_ke = integral0.integral_ke
                    }
                }).catch(function () {
                    that.$toast.fail('fail')
                })
            },
            onTixian(){
                let that = this
                if(that.tixian_value<=0){
                    that.$toast('提现金额必须大于0')
                    return
                }
                if(that.tixian_value>that.tixian_ke){
                    that.$toast('提现金额不得大于'+that.tixian_ke+'元')
                    return
                }
                that.$toast.loading({duration:0})
                that.$post(that.$api.mywithdraw_submit, {amt: that.tixian_value}).then(function (res) {
                    if(res.code === 1){
                        that.tixian_value = ''
                        that.$toast.success(res.msg)
                        that.getData()
                    }
                    else
                        that.$toast.fail(res.msg)
                }).catch(function () {
                    that.$toast.fail('fail')
                })
            },
            onRechange(){
                let that = this
                if(that.rechange_value<=0){
                    that.$toast('兑换数量必须大于0')
                    return
                }
                that.$toast.loading({duration:0})
                that.$post(that.$api.myrechange_submit, {num: that.rechange_value}).then(function (res) {
                    if(res.code === 1){
                        that.rechange_value = ''
                        that.$toast.success(res.msg)
                        that.getData()
                    }
                    else
                        that.$toast.fail(res.msg)
                }).catch(function () {
                    that.$toast.fail('fail')
                })
            },
            goBack(){
                //let that = this
                this.$router.push({
                    path: "/my" //that.formPath === '' ?'/':that.formPath
                })
            }
        },
        beforeRouteEnter(to, form, next){
            next(vm => {
                vm.formPath = form.path
            })
        }
    }
</script>

<style scoped>
    .tixian{background-color: #fff;overflow: hidden;padding: 2rem 1rem 5rem 1rem;}
    .tixian .tips{font-size:12px;color:#f60;background-color: #ffe395;padding:.3rem .5rem;border: solid 1px #fc0;border-radius: 3px;margin-bottom: 1rem;}
    .tixian .tips span{display: inline-block;vertical-align: 2px;margin-left:5px;}
    .tixian .name{color:#333;overflow: hidden;font-size:14px;margin-bottom: 1rem;}
    .tixian .name span{float:right;color:#666;}
    .tixian .name em{padding: 0 3px;color:#f00;}
    .tixian .put{border:solid 1px #ddd;border-radius: 3px;height:2.5rem;overflow: hidden;position: relative;}
    .tixian .put input{width:94%;padding:0 3%;height:2.5rem;border:0;font-size:16px;}
    .tixian .put span{display: block;position: absolute;right:10px;top:2px;line-height: 2.3rem;text-align: right;background: #fff;}
    .tixian button{background: #09f;color:#fff;width:100%;height:2.6rem;border:0;margin-top:2rem;font-size:14px;border-radius: 3px;}
    .tixian .link{text-align: center;margin-top:1rem;}
    .tixian .link a{color:#f90;}
    .tixian .text{margin-top:1rem;font-size:14px;color:#999;line-height: 22px;}
    .tixian .text div{color:#333;}
</style>