import Vue from 'vue'
import Router from 'vue-router'
import $store from "../store"

import My from '../components/my'
import Home from '../components/index'
import Login from '../components/login'
import Search from '../components/search'
import Recommend from '../components/recommend'
import RecommendView from '../components/recommend_view'
import Rank from '../components/rank'
import Bifen from "../components/bifen"
import Match from "../components/match"
import Register from '../components/register'
import Member from '../components/member'
import MemberList from '../components/member_list'
import Rule from '../components/rule'
import MyInfo from '../components/my/info'
import MySubscribe from '../components/my/subscribe'
import MyRecommend from '../components/my/recommend'
import MyCost from '../components/my/cost'
import MyCharge from '../components/my/recharge'
import MyNotice from '../components/my/notice'
import MyTixian from '../components/my/tixian'
import MyTixianList from '../components/my/tixian_list'
import MyFollow from '../components/my/follow'

import News from '../components/news'
import NewsView from '../components/news_view'
import Help from '../components/help'
import Feedback from '../components/feedback'
Vue.use(Router)

var router = new Router({
  mode: 'history',
  routes: [
    {path:'*', name: '404', redirect: '/'},
    {path:'/', name: 'Index', component: Home, meta: {footer: true, title: '首页'}},
    {path:'/search', name: 'search', component: Search, meta: {footer: false, title: '搜索'}},
    {path:'/recommend', name: 'recommend', component: Recommend, meta: {footer: true, title: '推介'}},
    {path:'/recommend/:rid', name: 'recommend', component: RecommendView, meta: {footer: false, title: '推介'}},
    {path:'/match/:mid', name: 'match', component: RecommendView,meta: {footer: false, title: '赛事'}},
    {path:'/match', name: 'match', component: Match, meta: {footer: false,title: '发布推介'}},
    {path:'/member', name: 'member', component: MemberList, meta: {footer: false, title: '热门专家'}},
    {path:'/member/:uid', name: 'member', component: Member, meta: {footer: false, title: '专家'}},
    {path:'/rank', name: 'rank', component: Rank, meta: {footer: true, title: '排行'}},
    {path:'/bifen', name: 'bifen', component: Bifen, meta: {footer: true, title: '比分'}},
    {path:'/help', name: 'help', component: Help, meta: {footer: true, title: '帮助'}},
    {path:'/feedback', name: 'feedback', component: Feedback, meta: {footer: true, title: '意见反馈'}},
    {path:'/news', name: 'news', component: News, meta: {footer: false, title: '新闻'}},
    {path:'/news/:nid', name: 'news_view', component: NewsView, meta: {footer: false, title: '新闻详情'}},
    {
      path:'/my',
      name: 'my',
      component: My,
      meta: {
        // auth: true,
        footer: true,
        title: '我的'
      }
    },
    {
      path:'/my/info',
      name: 'myinfo',
      component: MyInfo,
      meta: {
        auth: true,
        footer: false,
        title: '我的资料'
      }
    },
    {
      path:'/my/subscribe',
      name: 'mysubscribe',
      component: MySubscribe,
      meta: {
        auth: true,
        footer: false,
        title: '我的订阅'
      }
    },
    {
      path:'/my/recommend',
      name: 'myrecommend',
      component: MyRecommend,
      meta: {
        auth: true,
        footer: false,
        title: '我的推介'
      }
    },
    {
      path:'/my/sale',
      name: 'mysale',
      component: MyRecommend,
      meta: {
        auth: true,
        footer: false,
        title: '已售推介'
      }
    },
    {
      path:'/my/follow',
      name: 'myfollow',
      component: MyFollow,
      meta: {
        auth: true,
        footer: false,
        title: '我的关注'
      }
    },
    {
      path:'/my/cost',
      name: 'mycost',
      component: MyCost,
      meta: {
        auth: true,
        footer: false,
        title: '交易记录'
      }
    },
    {
      path:'/my/recharge',
      name: 'recharge',
      component: MyCharge,
      meta: {
        auth: true,
        footer: false,
        title: '充值'
      }
    },
    {
      path:'/my/notice',
      name: 'notice',
      component: MyNotice,
      meta: {
        auth: true,
        footer: false,
        title: '消息'
      }
    },
    {
      path:'/my/tixian',
      name: 'tixian',
      component: MyTixian,
      meta: {
        auth: true,
        footer: false,
        title: '提现兑换'
      }
    },
    {
      path:'/my/tixian/list',
      name: 'tixian_list',
      component: MyTixianList,
      meta: {
        auth: true,
        footer: false,
        title: '提现记录'
      }
    },
    {
      path:'/login',
      name: 'login',
      component: Login,
      meta:{
        //auth: true,
        footer: false,
        title: '登录'
      }
    },
    {
      path:'/register',
      name: 'register',
      component: Register,
      meta:{
        footer: false,
        title: '注册'
      }
    },
    {
      path:'/rule',
      name: 'rule',
      component: Rule,
      meta:{
        footer: false,
        title: '发布规则及奖励'
      }
    },
  ]
})
//验证路由
router.beforeEach((to, from, next) => {
  const {auth, footer, title} = to.meta
  // const {auth, footer} = to.meta

  if(to.name === 'login' && $store.state.token != null) {
    return
  }
  if(to.name === 'register' && $store.state.token) {
    return
  }

  footer === true ? $store.commit("SHOW_FOOTER") : $store.commit("HIDE_FOOTER")
  document.title = title + ' | AC足球理财网'

  if(auth === true && $store.state.token == null){
    //if (from.name === "login") return
    //console.log('要认证哦')
    next({path:'/login'})
  }

  next()
})

export default router
