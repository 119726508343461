<template>
    <div>
        <div id="header" class="fxwidth">
            <div class="hdback" @click="goBack"></div>
            <div class="hdtitle">搜索</div>
        </div>
            <div class="headerhd headerhd_lg"></div>

        <div class="fxwidth navtab searchpop">
            <div class="bx">
                <input type="text" placeholder="输入关键词" :focus="true" v-model="key" ref="refkey" @keyup.enter="toSearch" autofocus />
                <button @click="toSearch"></button>
            </div>

<!--            <span v-for="(item, index) in tabList" :key="index"-->
<!--                  :class="{curr:tabIndex===index}"-->
<!--                  @click="changeTab(index)">{{item}}<i v-if="index==0"></i></span>-->
        </div>

        <div class="skeletonbx" v-if="init === true">
            <van-skeleton row="5"></van-skeleton>
        </div>

        <div v-if="finished && list.length === 0"  class="list-none">没有数据~</div>
        <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
        <van-list v-model="loadmore"
                  :immediate-check="false"
                  :finished="finished"
                  :finished-text="list.length>0?'没有了':''"
                  :error.sync="error"
                  :error-text="list.length>0?'读取失败':''"
                  @load="loadMore">
            <ul class="tuijielist" v-if="list.length > 0">
                <li v-for="(c,i) in list" :key="i">
                    <div class="fxlab fxmy" v-if="c.mycommend">我的推介</div>
                    <div class="fxlab fxcm" v-if="c.isconsume">已购</div>
                    <div class="porta">
                        <router-link :to="'/member/'+c.user.id">
                            <img :src="c.user.portrait" :onerror="defimg" v-if="c.user.portrait"/>
                            <img :src="defimg" v-else/>
                            <span>{{c.user.name}}</span>
                            </router-link>
                        <em :if="c.user.winrate"><b>{{c.user.winrate}}%</b> 胜率</em>
                    </div>
                    <router-link :to="'/recommend/'+c.uuid">
                    <div class="con">
                        <div class="conlf">
                            <div class="tmss">
                                <span class="ss" :style="{background:c.color, color:'#fff'}">{{c.match_type}}</span>
                                <span class="time">{{c.time}}</span>
                            </div>
                            <div class="duiz">
                                    <span>{{c.shangpan}}</span>
                                    <em v-if="c.bifen" class="bf">{{c.bifen}}</em>
                                    <em v-else class="vs">vs</em>
                                    <span>{{c.xiapan}}</span>
                            </div>
                        </div>
                        <span :class="'rs rs'+c.result_id">{{c.result}}</span>
                    </div>
                    <div class="desc" v-if="c.commend_desc">推介分析：{{c.commend_desc}}</div>
                    <div class="detc">
                        <span class="tm">{{c.create}}发布</span>
                        <span v-if="c.showtype == 1" class="dai">待公开</span>
                        <span v-else-if="c.showtype == 2" class="kck"><em>{{c.gold}}</em>金豆查看</span>
<!--                        <span v-else-if="c.showtype == 3 || c.showtype == 4" class="gk"><em>荐</em>{{c.commend}}</span>-->
                        <span v-else-if="c.showtype == 3" class="gk">查看详情 &gt;</span>
                    </div>
                    </router-link>
                </li>
            </ul>
        </van-list>
        </van-pull-refresh>

    </div>
</template>

<script>
    import defPortrait from "../assets/images/user.png"
    export default {
        name: "recommend",
        data() {
            return {
                list: [],
                init: false,
                loadmore: false, //是否在加载更多，当滚动到底部时自动设置为true(不需手动设置)，每次加载完都得手动设置为false，
                loadmore_error: false,
                finished: false, //是否加载完毕
                error: false,     //是否读取错误，有错误自动设置为true，点击又重新读取更多（自动设置为false）
                refreshing: false,  //是否在下拉刷新
                pageIndex: 1,
                defimg: defPortrait,
                key: '',
                tabList: ['足球', '篮球'],
                tabIndex: 0,
                dataType: [1, 2],
                formPath: ''
            }
        },
        mounted() {
            this.$refs.refkey.focus()
        },
        activated() {
            // if(this.$route.query.key != undefined) {
            //     this.key = this.$route.query.key
            //     // this.getList()
            // }
            this.key = ''
            this.list = []
        },
        methods: {
            toSearch(){
                if(this.key === ''){
                    this.$nextTick(() =>{
                        this.$refs.refkey.focus()
                    })
                    return
                }
                this.list = []
                this.init = true
                this.onRefresh()
            },
            loadMore() {
                let that = this
                if(!that.loadmore_error)
                    that.pageIndex++
                that.getList()
            },
            changeTab(index){
                if (this.tabIndex === index)
                    return
                this.tabIndex = index
                this.list = []
                this.pageIndex = 1
                this.init = true
                this.error = false
                this.finished = false
                this.loadmore = false
                // this.key = ''
                this.getList()
            },
            onRefresh(){
                let that = this
                this.pageIndex = 1
                this.finished = false
                this.loadmore = false
                this.error = false
                that.getList()
            },
            getList(){
                let that = this
                that.loadmore_error = false
                that.$post(that.$api.recommend_list,
                    {page: that.pageIndex, key: that.key.trim(), type: that.dataType[that.tabIndex]})
                    .then(function (res) {
                    //console.log(res.data)
                        if(res.code === 1) {
                            let tmplist = res.data.recommend_list
                            if (tmplist.length > 0) {
                                tmplist.forEach(function (i) {
                                    if (i.user.portrait)
                                        i.user.portrait = that.$api.imgBaseUrl + i.user.portrait
                                })
                                if (that.refreshing)
                                    that.list = tmplist  //如果是下拉刷新则列表重置
                                else
                                    that.list = that.list.concat(tmplist)
                                if (res.data.end)
                                    that.finished = true
                            } else {
                                that.finished = true
                            }
                        }else{
                            that.finished = true
                        }
                }).catch(function () {
                    that.$toast.fail('读取失败')
                    that.error = true
                    that.loadmore_error = true
                }).finally(function () {
                    that.init = false
                    that.loadmore = false
                    that.refreshing = false
                })
            },
            goBack(){
                let that = this
                this.$router.push({
                    path: that.formPath === '' ?'/':that.formPath
                })
            }
        },
        beforeRouteEnter(to, form, next){
            next(vm => {
                vm.formPath = form.path
            })
        }
    }
</script>

<style scoped>
</style>