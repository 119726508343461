<template>
    <div>
        <div id="header" class="fxwidth">
            <div class="hdtitle">比分</div>
        </div>
        <div class="headerhd"></div>
        <div id="bfbox" :style="height">
            <iframe id="bffram" ref="iframe" src="https://m.7m.com.cn/live/index.html" @load="loadFrameFn"></iframe>
        </div>
    </div>
</template>

<script>
    export default {
        name: "bifen",
        data(){
          return {
              height: 'height:'+ (document.documentElement.clientHeight - 48) +'px;margin-top:-52px;margin-bottom:-40px;padding-bottom:-40px;overflow:hidden;'
          }
        },
        created() {
            // console.log(document.documentElement.clientHeight)
        },
        mounted() {
            // setTimeout(()=>{
            // this.loadFrameFn()
            // }, 2000)
        },
        methods: {
            loadFrameFn() {
                // // 获取iframe html文件
                // const doc = this.$refs.iframe.contentWindow.document.body
                // console.log(doc)
                // // 获取iframe html文件head
                // // const head = doc.getElementsByTagName('head')
                // // // console.log(head)
                // // // 新建link标签
                // // const linkTag = document.createElement('link')
                // // // link标签引入css文件的地址
                // // linkTag.href = '/css/bfiframe.css'
                // // // 设置link标签属性
                // // linkTag.setAttribute('rel', 'stylesheet')
                // // // 设置link标签属性
                // // linkTag.setAttribute('type', 'text/css')
                // // // 将link标签添加进iframe html文件的head里
                // // head[0].append(linkTag)
                // // console.log('loadend', linkTag)
                // // console.log(head[0])


                // const iframe = document.getElementById("bffram");
                // const body = iframe.contentWindow.document.body;
                // const cssLink = document.createElement("link");
                // cssLink.href = "/css/bfiframe.css";
                // cssLink.rel = "stylesheet";
                // cssLink.type = "text/css";
                // body.appendChild(cssLink);
            },
        }
    }
</script>

<style scoped>
    #bfbox{height:100%;}
    #bffram{
        width:100%;height:110%;margin-top:-60px;
    }
</style>
