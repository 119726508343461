<template>
    <div>
        <div id="header" class="fxwidth">
            <div class="hdback" @click="goBack"></div>
            <div class="hdtitle" v-if="isSale">已售推介</div>
            <div class="hdtitle" v-else>我的推介</div>
        </div>
            <div class="headerhd"></div>


        <div class="skeletonbx" v-if="list.length === 0 && init === true">
            <van-skeleton row="5"></van-skeleton>
        </div>
<div class="wrap">
    <div class="fltbox" v-if="init === false">
        <div class="name">共<em>{{list_count}}</em>条记录</div>
        <div class="item" @click="showTypePicker">{{type_text}}<i></i></div>
        <div class="item" @click="showDatePicker">{{date_text}}<i></i></div>
    </div>
        <van-pull-refresh v-model="refreshing" @refresh="onRefresh" v-if="list.length > 0">
        <van-list v-model="loadmore"
                  :immediate-check="false"
                  :finished="finished"
                  :finished-text="list.length>0?'到底了~':''"
                  :error.sync="error"
                  :error-text="list.length>0?'读取失败':''"
                  @load="loadMore">
            <ul class="subcrlist">
                <li v-for="(c,i) in list" :key="i" :class="'ball'+c.match.ball">
                    <van-swipe-cell>
                        <van-cell>
                    <div class="linetp">
                        <span class="time">发布于{{c.create_time}}</span>
                        <span class="ding" v-if="c.count_dy" @click="showscr(c.id)"><em>{{c.count_dy}}</em>场订阅</span>
                        <span class="gold"><em>{{c.gold}}</em>金豆</span>
                    </div>
                    <router-link :to="'/recommend/'+c.match.id">
                    <div class="linecn">
                        <span class="sais" :style="'color:'+c.match.color">{{c.match.type}}</span>
                        <span class="ctime">{{c.match.time}}</span>
                    </div>
                    <div class="linemt">
                        <span>{{c.match.shangpan}}</span>
                        <b v-if="c.match.bf">{{c.match.bf}}</b>
                        <em v-else>VS</em>
                        <span>{{c.match.xiapan}}</span>
                    </div>
                    <div :class="'rs rs'+c.match.result_id">
                        {{c.match.result}}
                    </div>
                        <div v-if="c.desc" class="desc">推介分析：{{c.desc}}</div>
                    </router-link>
                        </van-cell>
                        <template #right>
                            <van-button square type="danger" @click="delscr(i)" text="删除" />
                        </template>
                    </van-swipe-cell>
                </li>
            </ul>
        </van-list>
        </van-pull-refresh>
        <div v-if="list.length === 0 && init === false"  class="list-none">没有记录~</div>
</div>

        <van-popup position="bottom" v-model="showPopup" :duration=".3">
            <div class="popbox">
                <div class="popboxtitle">订阅用户</div>
                <div class="popboxcon mymatchdypop">
                    <div v-for="(c,i) in subscribe_user_list" :key="i" class="item">
                        <router-link :to="'/member/'+c.uid">
                        <img :src="c.portrait"/>
                        <span>{{c.name}}</span>
                            <em>{{c.create}}</em>
                        </router-link>
                    </div>
                </div>
            </div>
        </van-popup>

        <van-popup position="bottom" v-model="typepicker">
            <van-picker
                    title="标题"
                    show-toolbar
                    :columns="type_list"
                    @confirm="onTypeConfirm"
                    @cancel="onTypeCancel"
            />
        </van-popup>

        <van-popup position="bottom" v-model="datepicker">
            <van-datetime-picker
                    v-model="currentDate"
                    type="year-month"
                    title="选择年月"
                    :min-date="minDate"
                    :max-date="maxDate"
                    :formatter="formatter"
                    @cancel="onDateCancel"
                    @confirm="onDateConfirm"
            />
        </van-popup>



    </div>
</template>

<script>
    export default {
        name: "tuijie",
        data() {
            return {
                showPopup: false,
                list: [],
                init: true,
                loadmore: false, //是否在加载更多，当滚动到底部时自动设置为true(不需手动设置)，每次加载完都得手动设置为false，
                loadmore_error: false,
                finished: false, //是否加载完毕
                isloading: false, //是否正在读取
                error: false,     //是否读取错误，有错误自动设置为true，点击又重新读取更多（自动设置为false）
                refreshing: false,  //是否在下拉刷新
                pageIndex: 1,
                list_count: 0,
                typepicker: false,
                type: 0,  //数据类型 0所有
                type_list: ['全部', '足球', '篮球'],
                type_text: '全部',
                date_text: '选择日期',
                date_value: '',
                currentDate: new Date(),
                datepicker: false,
                minDate: new Date(2020, 0, 1),
                maxDate: new Date(),
                isSale: 0,
                subscribe_user_list: [], //订阅用户
                pageIndex_user: 1,
                subscribe_select: 0,
                formPath: ''
            }
        },
        activated() {
            // let that = this
            this.isSale = 0
            this.init = true
            this.list = []
            this.onInitData()
            if(this.$route.path === '/my/sale') {
                this.isSale = 1
            }
            this.getList()
        },
        methods: {
            loadMore() {
                let that = this
                if(!that.loadmore_error)
                    that.pageIndex++
                that.getList()
            },
            onInitData(){
                let that = this
                that.list_count = 0
                that.pageIndex = 1
                that.finished = false
                that.loadmore = false
                that.error = false
            },
            onRefresh(){
                let that = this
                that.onInitData()
                that.refreshing = true
                that.getList()
            },
            getList(){
                let that = this
                that.loadmore_error = false
                that.$post(that.$api.myrecommend, {page: that.pageIndex, type: that.type, sale: that.isSale, time: that.date_value})
                    .then(function (res) {
                    that.list_count = res.data.count
                    let tmplist = res.data.recommend_list
                    if(tmplist.length>0) {
                        if(that.refreshing || that.init)
                            that.list = tmplist  //如果是下拉刷新则列表重置
                        else
                            that.list = that.list.concat(tmplist)
                        if (res.data.end)
                            that.finished = true
                    }
                    else{
                        if(that.refreshing || that.init)
                            that.list = []
                        that.finished = true
                    }
                }).catch(function () {
                    that.$toast.fail('读取失败')
                    that.error = true
                    that.loadmore_error = true
                }).finally(function () {
                    that.init = false
                    that.loadmore = false
                    that.isloading = false
                    that.refreshing = false
                })
            },
            showscr(id){
                let that = this
                that.showPopup = true
                // console.log(id)
                that.subscribe_select = id
                that.$post(that.$api.myrecommend_user, {page: that.pageIndex_user, rid: that.subscribe_select})
                    .then(function (res) {
                        that.list_count = res.data.count
                        let tmplist = res.data.user_list
                        if (tmplist.length > 0) {
                            tmplist.forEach(function (i) {
                                if(i.portrait)
                                    i.portrait = that.$api.imgBaseUrl + i.portrait
                            })
                            that.subscribe_user_list = tmplist
                        }
                    })
            },
            delscr(index){
                let that = this
                that.$dialog.confirm({
                    title: '确认',
                    message: '确定删除该条记录？',
                }).then( () => {
                    let id = that.list[index].id
                    that.$toast.loading({message: '处理中', forbidClick: true, duration: 0})
                    that.$post(that.$api.myrecommend_del, {sid: id})
                        .then(function (res) {
                            //console.log(res)
                            if(res.code === 1){
                                that.list.splice(index,1)
                                that.$toast.success(res.msg)
                            }
                            else{
                                that.$toast.fail(res.msg)
                            }
                        }).catch(function () {
                        that.$toast.fail('fail')
                    })
                })
            },
            showDatePicker(){
                this.datepicker = true
            },
            onDateConfirm(value){
                let y = value.getFullYear(), m = value.getMonth() + 1
                this.date_text =  y+'年'+m+'月'
                this.date_value = y+'-'+m
                this.datepicker = false
                this.onRefresh()
            },
            onDateCancel(){
                this.datepicker = false
            },
            formatter(type, val) {
                if (type === 'month') {
                    return `${val}月`;
                } else if (type === 'day') {
                    return `${val}日`;
                }
                return val;
            },

            showTypePicker(){
                this.typepicker = true
            },
            onTypeConfirm(value, index){
                this.type = index
                this.type_text = this.type_list[index]
                this.typepicker = false
                this.onRefresh()
            },
            onTypeCancel(){
                this.typepicker = false
            },
            goBack(){
                //let that = this
                this.$router.push({
                    path: "/my" //that.formPath === '' ?'/':that.formPath
                })
            }
        },
        beforeRouteEnter(to, form, next){
            next(vm => {
                vm.formPath = form.path
            })
        }
    }
</script>

<style scoped>
</style>