<template>
    <div>
        <van-popup class="fxwidth" position="bottom" v-model="showPopup" @click-overlay='close' :duration=".3">
            <div class="popbox matchpopbox">
                <div class="popboxtitle">登录<div class="close" @click="close"></div></div>
                <div class="popboxcon loginpop">
                    <div class="item">
                        <input type="text" placeholder="账号" v-model="account" ref="refacc" autocomplete="off" autofocus="autofocus"/>
                    </div>
                    <div class="item">
                        <input type="password" placeholder="密码" v-model="password" ref="refpwd" autocomplete="off"/>
                    </div>
                    <div class="item">
                        <input type="number" placeholder="验证码" v-model="yzmcode" ref="refcode" autocomplete="off"/>
                        <YZM @getIdentifyCode="valcode" :changeCode="changeCode" class="yzm" />
                    </div>
<!--                    <div class="code">-->
<!--                        <JcRange status="jcRange_status" :successFun="jcRangeSuccess"></JcRange>-->
<!--                    </div>-->
                    <div><button type="button" @click="toLogin">登录</button></div>
                    <p>没有账号？<span @click="goReg">立即注册</span></p>
                </div>
            </div>
        </van-popup>
<!--        <div id="loginpop">弹窗登录</div>-->
    </div>
</template>

<script>
    // import JcRange from "./JcRange";
    import YZM from "./yzcode";
export default {
    name: "loginpop",
    components: {
        // JcRange,
        YZM
    },
    data(){
        return {
            account: '',
            password: '',
            yzmcode: '',
            yzm: '',
            changeCode: 0,
            showPopup: true,
            // jcRange_status: false,
        }
    },
    methods: {
        // jcRangeSuccess: function(){
        //     this.jcRange_status = true
        // },
        close: function(){
            this.$store.commit('HIDE_LOGINPOP')
        },
        valcode(data){
            this.yzm = data
        },
        toLogin: function () {
            let that = this
            if(that.account === ''){
                this.$toast('请输入账号')
                this.$nextTick(() =>{
                    this.$refs.refacc.focus()
                })
                return
            }
            if(that.password === ''){
                this.$toast('请输入密码')
                this.$nextTick(() =>{
                    this.$refs.refpwd.focus()
                })
                return
            }
            if(that.yzmcode === ''){
                that.$toast('请输入验证码')
                that.$nextTick(() =>{
                    that.$refs.refcode.focus()
                })
                return
            }
            if(that.yzmcode !== that.yzm){
                that.$toast('验证码不正确')
                that.changeCode = Math.random()
                that.$nextTick(() =>{
                    that.$refs.refcode.focus()
                })
                return
            }
            // if(!that.jcRange_status){
            //     this.$toast('请拖动滑块验证')
            //     return
            // }
            that.$toast.loading({
                message: '登录中',
                duration: 0,
                forbidClick: true
            })
            setTimeout(function () {
                that.$post(that.$api.login, {name: that.account, pwd: that.password}).then(function (res) {
                    //console.log(res)
                    if(res.code === 1) {
                        that.$store.commit('LOGIN', res.data.token)
                        that.$toast.success({
                            message: res.msg,
                            forbidClick: true,
                            onClose: function () {
                                //关闭pop
                                that.close()
                            }
                        })
                    }
                    else{
                        that.$toast.fail(res.msg)
                    }
                })
            }, 300)
        },
        goReg: function () {
            this.close()
            this.$router.push('/register')
        }
    }
}
</script>

<style scoped>
</style>
