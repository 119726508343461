<template>
    <div>
        <div id="header" class="fxwidth">
            <div class="hdback" @click="goBack"></div>
            <div class="hdtitle">每日精选单场</div>
        </div>
            <div class="headerhd"></div>

        <div class="newsbox">
        <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
        <van-list v-model="loadmore"
                  :immediate-check="false"
                  :finished="finished"
                  finished-text="没有更多了"
                  :error.sync="error"
                  :error-text="list.length>0?'读取失败':''"
                  @load="loadMore">
            <ul class="newslist" v-if="list.length > 0">
                <li v-for="(c,i) in list" :key="i">
                    <div><router-link :to="/news/+c.id">{{c.title}}</router-link></div>
                    <span>{{c.time}}</span>
                </li>
            </ul>
        </van-list>
        </van-pull-refresh>
        </div>
    </div>
</template>

<script>
    export default {
        name: "news",
        data() {
            return {
                list: [],
                init: true,
                loadmore: false, //是否在加载更多，当滚动到底部时自动设置为true(不需手动设置)，每次加载完都得手动设置为false，
                loadmore_error: false,
                finished: false, //是否加载完毕
                isloading: false, //是否正在读取
                error: false,     //是否读取错误，有错误自动设置为true，点击又重新读取更多（自动设置为false）
                refreshing: false,  //是否在下拉刷新
                pageIndex: 1,
                formPath: ''
            }
        },
        watch: {
          '$route'(v){
              // console.log(v)
              if(v.name === 'news')
                this.initData()
          }
        },
        created() {
            this.list = []
            this.getList()
            // console.log('created')
        },
        methods: {
            initData(){
                let that = this
                that.list = []
                that.init = true
                that.loadmore = false
                that.loadmore_error = false
                that.finished = false
                that.isloading = false
                that.error = false
                that.refreshing = false
                that.pageIndex = 1
                // console.log('init')
                // console.log(that.list.length)
                document.documentElement.scrollTop = 0
                this.getList()
            },
            loadMore() {
                // console.log('load more')
                let that = this
                if(!that.loadmore_error)
                    that.pageIndex++
                that.getList()
                // console.log('load more')
            },
            onRefresh(){
                let that = this
                this.lastId = 0
                this.pageIndex = 1
                this.finished = false
                this.loadmore = false
                this.error = false
                that.getList()
                // console.log('refresh')
            },
            getList(){
                let that = this
                that.loadmore_error = false

                // console.log(that.list.length)
                that.$post(that.$api.news_list, {page: that.pageIndex}).then(function (res) {
                    //console.log(res.data)
                    let tmplist = res.data.news_list
                    if(tmplist.length>0) {
                        // console.log(that.list.length)
                        if(that.refreshing) {
                            // console.log('refreshing')
                            that.list = tmplist  //如果是下拉刷新则列表重置
                        }else {
                            // console.log('concat')
                            that.list = that.list.concat(tmplist)
                        }
                        if (res.data.end)
                            that.finished = true
                    }
                    else{
                        that.finished = true
                    }
                }).catch(function () {
                    that.$toast.fail('读取失败')
                    that.error = true
                    that.loadmore_error = true
                }).finally(function () {
                    // console.log('finally')
                    that.init = false
                    that.loadmore = false
                    that.isloading = false
                    that.refreshing = false
                })
            },
            goBack(){
                let that = this
                this.$router.push({
                    path: that.formPath === '' ?'/':that.formPath
                })
            },
        }
    }
</script>

<style scoped>
    .newsbox{
        width: 100%;
        height: calc(100vh - 50px);
        overflow-y: auto;
        /*height: calc(100vh - 50px);overflow: hidden;*/
    }
</style>
