<template>
    <div class='fxwidth' id="footer_nav">
        <router-link :class="item.url === $route.path?'active':''" v-for="(item,index) in footerList" :key="index" :to="item.url">
            <i :class="'icon'+index"><em v-if="index === 4 && notice>0"></em></i>
            <span>{{item.name}}</span>
        </router-link>
    </div>
    
</template>

<script>
export default {
    name: "Footer",
    data(){
        return {
            notice: 0, //this.$store.state.notice,
            footerList: [
                {
                    id: 1,
                    name: '首页',
                    url: '/'
                },
                {
                    id: 2,
                    name: '推介',
                    url: '/recommend'
                },
                {
                    id: 3,
                    name: '排行',
                    url: '/rank'
                },
                // {
                //     id: 3,
                //     name: '比分',
                //     url: '/bifen'
                // },
                {
                    id: 4,
                    name: '我的',
                    url: '/my'
                },
            ]
        }
    },
    created() {
        //console.log(2)
        this.getNotice()
    },
    methods: {
        getNotice(){
            let that = this
            setInterval(function () {
                let notice = 0
                if (that.$store.state.token != null) {
                    that.$post(that.$api.getnotice).then(function (res) {
                        // console.log(res)
                        if (res.code === 1) {
                            // console.log(notice)
                            notice = res.data.notice_count
                        }
                        that.$store.commit('SET_NOTICE', notice)
                        that.notice = notice
                    })
                }
                else{
                    that.$store.commit('SET_NOTICE', notice)
                    that.notice = notice
                }
            }, 1000 * 60 * 2)
        }
    }
}
</script>

<style scoped>
</style>