<template>
    <div>
        <div id="header" class="fxwidth">
            <div class="hdback" @click="goBack"></div>
            <div class="hdtitle">充值</div>
        </div>
            <div class="headerhd"></div>

<div class="wrap">
    <div class="recharge">
        请联系客服充值
        <img src="../../assets/images/wechat.jpg"/>
        <p>微信扫一扫</p>
    </div>
</div>

    </div>
</template>

<script>
    export default {
        name: "recharge",
        data() {
            return {
                showPopup: false,
                formPath: ''
            }
        },
        activated() {

        },
        methods: {
            goBack(){
                //let that = this
                this.$router.push({
                    path: "/my" //that.formPath === '' ?'/':that.formPath
                })
            }
        },
        beforeRouteEnter(to, form, next){
            next(vm => {
                vm.formPath = form.path
            })
        }
    }
</script>

<style scoped>
    .recharge{padding:3rem 1rem;overflow: hidden;text-align: center;}
    .recharge img{width:8rem;height:8rem;background-color: #ddd;display: block;margin:2rem auto 1rem auto;}
    .recharge p{font-size:13px;color:#999;}
</style>