<template>
    <div>

        <ul :class="['tuijielist', type===0?'hotlist':'']" v-if="list.length > 0">
            <li v-for="(c,i) in list" :key="i">
                <div class="fxlab fxmy" v-if="c.mycommend">我的推介</div>
                <div class="fxlab fxcm" v-if="c.isconsume">已购</div>
                <div class="porta">
                    <router-link :to="'/member/'+c.user.id">
                        <img :src="c.user.portrait" :onerror="defimg" v-if="c.user.portrait"/>
                        <img :src="defimg" v-else/>
                        <span>{{c.user.name}}</span>
                    </router-link>
                    <em :if="c.user.winrate"><b>{{c.user.winrate}}%</b> 胜率</em>
                </div>
                <router-link :to="'/recommend/'+c.uuid">
                    <div class="con">
                        <div class="conlf">
                            <div class="tmss">
                                <span class="ss" :style="{background:c.color, color:'#fff'}">{{c.match_type}}</span>
                                <span class="time">{{c.time}}</span>
                            </div>
                            <div class="duiz">
                                <span>{{c.shangpan}}</span>
                                <em v-if="c.bifen" class="bf">{{c.bifen}}</em>
                                <em v-else class="vs">vs</em>
                                <span>{{c.xiapan}}</span>
                            </div>
                        </div>
                        <span :class="'rs rs'+c.result_id">{{c.result}}</span>
                    </div>
                    <div class="desc" v-if="c.commend_desc">推介分析：{{c.commend_desc}}</div>
                    <div class="detc">
                        <span class="tm">{{c.create}}发布</span>
                        <span v-if="c.showtype == 1" class="dai">待公开</span>
                        <span v-else-if="c.showtype == 2" class="kck"><em>{{c.gold}}</em>金豆查看</span>
                        <!--                        <span v-else-if="c.showtype == 3 || c.showtype == 4" class="gk"><em>荐</em>{{c.commend}}</span>-->
                        <span v-else-if="c.showtype == 3" class="gk">查看详情 &gt;</span>
                    </div>
                </router-link>
            </li>
        </ul>

    </div>
</template>

<script>
    import defPortrait from "../../assets/images/user.png"
    export default {
        name: "index",
        props: ['list', 'type'],
        data() {
            return {
                defimg: defPortrait
            }
        },
        created() {

        },
        methods: {

        }
    }
</script>

<style scoped>
</style>
