<template>
  <div id="app">
    <!--<Header v-if="footer == true"></Header>-->
    <keep-alive>
      <router-view ref="index" v-if="isRouterAlive"></router-view>
    </keep-alive>
    <Footer v-if="footer === true"></Footer><!--过滤底部-->
<!--    <Footer/> &lt;!&ndash;全部显示&ndash;&gt;-->
    <LoginPop v-if="showlogin == true"></LoginPop>
  </div>
</template>

<script>
import Footer from './components/public/footer'
import LoginPop from './components/public/loginPop'
//import Header from './components/header'
import { mapGetters } from "vuex"

export default {
  name: 'App',
  computed: mapGetters(["footer", 'showlogin']),
  components: {
    LoginPop,
    Footer,
    //Header,
  },
  data(){
    return {
      isRouterAlive: true
    }
  },
  provide(){
    return {
      reload: this.reload
    }
  },
  methods: {
    reload(){
      this.isRouterAlive = false
      this.$nextTick(function () {
        this.isRouterAlive = true
      })
    }
  },
  mounted() {
    //每次执行前，先移除上次插入的代码
    var s = document.getElementsByTagName("script")[0];
    document.getElementById('LA_COLLECT') && document.getElementById('LA_COLLECT').remove();
    var hm_la = document.createElement("script");
    hm_la.src = "//sdk.51.la/js-sdk-pro.min.js?id=KPDJvUhnGewaTxnQ&ck=KPDJvUhnGewaTxnQ";
    hm_la.id = "LA_COLLECT"
    hm_la.charset = "UTF-8"
    var s_la = document.getElementsByTagName("script")[0];
    s.parentNode.insertBefore(hm_la, s_la);
  }
}

// npm run build
</script>

<style>
</style>
