<template>
    <div v-if="islogin">

        <div v-if="member">
            <div class="portrait" v-if="member">
                <div class="img"><img :src="member.portrait == ''?defimg:member.portrait" v-if="member"/></div>
                <div class="con">
                <div class="name">{{member.name}}</div>
                <div class="dsc">{{member.intro}}</div>
                </div>
            </div>
            <div class="mycoin" v-if="member">
                <div class="item">
                    <b>{{member.gold}}</b>
                    <div>金豆 <em></em></div>
                </div>
                <div class="item">
                    <b>{{member.integral}}</b>
                    <div @click="showPopupbox">积分<van-icon name="question-o"></van-icon></div>
                </div>
            </div>
            <div class="mybox">
                <div class="item"><router-link to="/my/recommend"><i class="tj"></i>我的推介</router-link></div>
                <div class="item"><router-link to="/my/sale"><i class="sc"></i>我的售出</router-link></div>
                <div class="item"><router-link to="/my/follow"><i class="fl"></i>我的关注</router-link></div>
                <div class="item"><router-link to="/my/subscribe"><i class="dy"></i>我的订阅</router-link></div>
                <div class="item"><router-link to="/my/cost"><i class="cz"></i>交易记录</router-link></div>
                <div class="item"><router-link to="/my/notice"><i class="mg"></i>我的消息<van-badge :content="notice" v-if="notice>0" class="nt" /></router-link></div>
    <!--            <div class="item"><i class="gz"></i>我的关注</div>-->
    <!--            <div class="item"><router-link to="/my/collect"><i class="sc"></i>我的收藏</router-link></div>-->
                <div class="item"><router-link to="/my/recharge"><i class="rc"></i>在线充值</router-link></div>
                <div class="item"><router-link to="/my/tixian"><i class="tx"></i>提现兑换</router-link></div>
                <div class="item"><router-link to="/my/info"><i class="in"></i>账号管理</router-link></div>

<!--                <div class="item out"><router-link to="/feedback"><i class="yj"></i>意见反馈</router-link></div>-->
                <div class="item"><router-link to="/help"><i class="hp"></i>帮助中心</router-link></div>

                <div class="item out" @click="tologout"><i class="et"></i>退出登录</div>
            </div>

            <van-popup position="bottom" v-model="showPopup" :duration=".3">
                <div class="popbox">
                    <div class="popboxtitle">积分说明</div>
                    <div class="popboxcon mypopcon">
                        <p class="t">积分使用方法</p>
                        <p>积分可兑换成金豆进行消费，100积分=1金豆</p>
                        <p></p>
                        <p class="t">通过以下方法可获得积分</p>
                        <p>1. 每日签到领取1-10个积分</p>
                        <p>2. 发布一场推介可获得10个积分，每日最多发布10场推介</p>
<!--                        <p>...</p>-->
                    </div>
                </div>
            </van-popup>
        </div>

        <div class="skeletonbx" v-else>
            <van-skeleton row="5"></van-skeleton>
        </div>

    </div>
    <div v-else>
        <div class="portrait">
            <div class="img img_s"><img :src="defimg"/></div>
            <div class="con">
                <router-link to="/login">请登录</router-link><span>/</span><router-link to="/register">注册</router-link></div>
        </div>
<!--        <div class="skeletonbx">-->
<!--        <van-skeleton row="5" row-width="100%" title="true"></van-skeleton>-->
<!--        </div>-->
        <div class="mybox">
<!--            <div class="item"><router-link to="/feedback"><i class="yj"></i>意见反馈</router-link></div>-->
            <div class="item"><router-link to="/help"><i class="hp"></i>帮助中心</router-link></div>
        </div>
    </div>


</template>

<script>
    import defPortrait from "../assets/images/user.png"
    export default {
        name: "my",
        data (){
          return {
              notice: 0,
              member: null,
              defimg: defPortrait,
              islogin: false,
              showPopup: false,
          }
        },
        activated() {
            let that = this, token = that.$store.state.token, notice = that.$store.state.notice
            if(token === null || token === '' || token === undefined){
                return
            }
            that.islogin = true
            that.notice = notice
            this.$post(that.$api.memberinfo).then(function (res) {
                //console.log(res)
                if (res.code === 1){
                    let data = res.data.member
                    if(data.portrait === null)
                        data.portrait = ''
                    else
                        data.portrait = that.$api.imgBaseUrl + data.portrait
                    that.member = data
                }
            }).catch(function () {
                that.$toast.fail('fail')
            })
        },
        created() {
        },
        methods: {
            showPopupbox(){
                this.showPopup = true
            },
            tologout() {
                let that = this
                that.$dialog.confirm({
                    title: '确认',
                    message: '确定退出登录？',
                }).then( () => {
                    that.$store.commit('LOGOUT')
                    location.reload()
                }).catch( () => {
                    //no fun
                })
            }
        }
    }
</script>

<style scoped>
    .mypopcon{text-align: left;font-size:14px;padding:10px 30px 30px 30px;}
    .mypopcon p{line-height: 1.6rem;}
    .mypopcon p.t{margin-top:.6rem;color:#f60;}
</style>