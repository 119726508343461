<template>
    <div>
        <div id="header" class="fxwidth">
            <div class="hdback" @click="goBack"></div>
            <div class="hdtitle">发布推介</div>
            <router-link class="hdbtn" to="/rule">规则奖励</router-link>
        </div>
        <div class="headerhd headerhd_lg"></div>

        <div class="fxwidth navtab">
            <span v-for="(item, index) in tabList" :key="index" :class="{curr:tabIndex===index}" @click="changeTab(index)">{{item}}</span>
        </div>

        <div class="skeletonbx" v-if="init === true">
            <van-skeleton row="5"></van-skeleton>
        </div>

        <div v-if="list.length === 0 && init === false"  class="list-none">没有数据~</div>
        <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
        <van-list v-model="loadmore"
                  :immediate-check="false"
                  :finished="finished"
                  :finished-text="list.length>0?'没有了':''"
                  :error.sync="error"
                  :error-text="list.length>0?'读取失败':''"
                  @load="loadMore">
            <ul class="matchlist" v-if="list.length > 0">
                <li v-for="(c,i) in list" :key="i">
                    <div class="tmss">
                        <span class="ss" :style="{background:c.color, color:'#fff'}">{{c.matchtype}}</span>
                        <span class="time">{{c.time}}</span>
                        <router-link class="tn" :to="'/match/'+c.uuid" v-if="c.recommend_num > 0"><em>{{c.recommend_num}}</em>场推介</router-link>
                    </div>
                    <div class="acte">
                        <div class="duiz">
                            <em class="vs">vs</em>
                            <div class="duizcn">
                                <span class="sp"><router-link :to="'/match/'+c.uuid">{{c.shangpan}}</router-link></span>
                                <span class="xp"><router-link :to="'/match/'+c.uuid">{{c.xiapan}}</router-link></span>
                            </div>
                        </div>
                        <button @click="showPublishPopBox(i)">我要发布</button>
                    </div>
                </li>
            </ul>
        </van-list>
        </van-pull-refresh>
        <Publish v-if="showPublishPopup"/>
    </div>
</template>

<script>
    import defPortrait from "../assets/images/user.png"
    import Publish from "./public/publish";
    export default {
        name: "match",
        components: {Publish},
        comments: {
            Publish
        },
        data() {
            return {
                list: [],
                init: true,
                loadmore: false, //是否在加载更多，当滚动到底部时自动设置为true(不需手动设置)，每次加载完都得手动设置为false，
                loadmore_error: false,
                finished: false,
                error: false,
                refreshing: false,
                pageIndex: 1,
                defimg: defPortrait,
                tabList: ['足球', '篮球'],
                tabIndex: 0,
                dataType: [1, 2],

                showPublishPopup: false,
                selectMatchId: 0,
                selectIndex: 0,
                formPath: ''
            }
        },
        activated() {
            this.getList()
        },
        methods: {
            loadMore() {
                let that = this
                if(!that.loadmore_error)
                    that.pageIndex++
                that.getList()
            },
            changeTab(index){
                if (this.tabIndex === index)
                    return
                this.tabIndex = index
                this.list = []
                this.pageIndex = 1
                this.init = true
                this.error = false
                this.finished = false
                this.loadmore = false
                this.getList()
            },
            onRefresh(){
                let that = this
                this.pageIndex = 1
                this.finished = false
                this.loadmore = false
                this.error = false
                that.getList()
            },
            getList(){
                let that = this
                that.loadmore_error = false
                that.$post(that.$api.match_list, {page: that.pageIndex, type: that.dataType[that.tabIndex]}).then(function (res) {
                    //console.log(res.data.list)
                    let tmplist = res.data.match_list
                    if(tmplist.length>0) {
                        if(that.refreshing)
                            that.list = tmplist  //如果是下拉刷新则列表重置
                        else
                            that.list = that.list.concat(tmplist)
                        if (res.data.end)
                            that.finished = true
                    }
                    else{
                        that.finished = true
                    }
                }).catch(function () {
                    that.$toast.fail('读取失败')
                    that.error = true
                    that.loadmore_error = true
                }).finally(function () {
                    that.init = false
                    that.loadmore = false
                    that.refreshing = false
                })
            },
            showPublishPopBox(index){
                let token = this.$store.state.token, that = this
                if(token === null || token === '' || token === undefined){
                    //that.$toast({message: '请登录'})
                    that.$store.commit('SHOW_LOGINPOP')
                    return
                }
                //显示发布弹窗
                this.showPublishPopup = true
                let matchid =  this.list[index].id
                this.selectMatchId = matchid
                this.selectIndex = index
            },
            publishSuccess(){
                //发布推介成功
                let that = this
                that.showPublishPopup = false
                let tmplist = that.list
                tmplist[that.selectIndex].recommend_num += 1
                that.list = tmplist
            },
            closePublishPopBox(){
                //关闭发布弹窗
                let that = this
                that.showPublishPopup = false
            },
            goBack(){
                // let that = this
                this.$router.push({
                    path: '/recommend' //that.formPath === '' ?'/':that.formPath
                })
            }
        },
        beforeRouteEnter(to, form, next){
            next(vm => {
                vm.formPath = form.path
            })
        }
    }
</script>

<style scoped>
</style>
