<template>
    <div>
        <div id="header" class="fxwidth">
            <div class="hdback" @click="goBack"></div>
            <div class="hdtitle">新闻详情</div>
        </div>
            <div class="headerhd"></div>

        <div v-if="news" class="newsdetail">
            <h1>{{news.title}}</h1>
            <div class="time">时间：{{news.time}}</div>
            <div class="content" v-html="news.content"></div>
            <div class="pgnext">
                <p v-if="news_prev">上一篇：<router-link :to="/news/+news_prev.id">{{news_prev.title}}</router-link></p>
                <p v-if="news_next">下一篇：<router-link :to="/news/+news_next.id">{{news_next.title}}</router-link></p>
            </div>
        </div>

    </div>
</template>

<script>
    export default {
        name: "news",
        data() {
            return {
                news: null,
                init: true,
                formPath: '',
                news_prev: null,
                news_next: null
            }
        },
        watch:{
          '$route': function(v){
              let nid = v.params.nid
              this.news_prev = null
              this.news_next = null
              this.getData(nid)
              document.documentElement.scrollTop = 0
          }
        },
        activated() {
            let that = this,
                nid = this.$route.params.nid
            that.getData(nid)
        },
        methods: {
            getData(nid){
                let that = this
                that.$post(that.$api.news_info, {nid: nid}).then(function (res) {
                    if(res.news){
                        that.news = res.news
                    }
                    if(res.news_prev)
                        that.news_prev = res.news_prev
                    if(res.news_next)
                        that.news_next = res.news_next
                }).catch(function () {

                })
            },
            goBack(){
                let that = this
                this.$router.push({
                    path: that.formPath === '' ?'/':that.formPath
                })
            }
        }
    }
</script>

<style scoped>
</style>
