import Vue from 'vue'
import Vuex from 'vuex'
import getters from './getters'
import cookie from '../utils/cookie'

const LOGIN_KEY = "tqrlogin_status"
Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        footer: true,
        token: cookie.get(LOGIN_KEY) || null,
        notice: 0,
        showlogin: false,
    },
    mutations: {
        //更新值
        SHOW_FOOTER(state){
            state.footer = true
        },
        HIDE_FOOTER(state){
            state.footer = false
        },
        SHOW_LOGINPOP(state){
            state.showlogin = true
        },
        HIDE_LOGINPOP(state){
            state.showlogin = false
        },
        SET_NOTICE(state, notice){
            state.notice = notice
        },
        LOGIN(state, token, expires_time) {
            state.token = token
            cookie.set(LOGIN_KEY, token, expires_time)
        },
        LOGOUT(state) {
            state.token = null
            cookie.remove(LOGIN_KEY)
            //cookie.set(LOGIN_KEY, '000', expires_time)
        },
    },
    getters
})