<template>
    <div>
        <div id="header" class="fxwidth">
            <div class="hdback" @click="goBack"></div>
            <div class="hdtitle">交易记录</div>
        </div>
            <div class="headerhd"></div>

        <div class="skeletonbx" v-if="list.length === 0 && init === true">
            <van-skeleton row="5"></van-skeleton>
        </div>
<div class="wrap">
        <div class="fltbox" v-if="init === false">
            <div class="name">共<em>{{list_count}}</em>条记录</div>
            <div class="item" @click="showTypePicker">{{type_text}}<i></i></div>
            <div class="item" @click="showDatePicker">{{date_text}}<i></i></div>
        </div>
        <van-pull-refresh v-model="refreshing" @refresh="onRefresh" v-if="list.length > 0">
        <van-list v-model="loadmore"
                  :immediate-check="false"
                  :finished="finished"
                  :finished-text="list.length>0?'没有更多内容了':''"
                  :error.sync="error"
                  :error-text="list.length>0?'读取失败':''"
                  @load="loadMore">
            <ul class="subcrlist costlist">
                <li v-for="(c,i) in list" :key="i">
                    <van-swipe-cell>
                        <van-cell>
                            <div class="linetp">
                                <span class="time">时间：{{c.create_time}}</span>
                            </div>
                            <div class="con">
                                <div class="type">【{{c.type}}】
                                    <span v-if="c.gold">
                                        <span class="amt" v-if="c.gold > 0"><em class="amtj">+{{c.gold}}</em>金豆</span>
                                        <span class="amt" v-else-if="c.gold === 0"><em>{{c.gold}}</em>金豆</span>
                                        <span class="amt" v-else><em class="amtz">{{c.gold}}</em>金豆</span>
                                    </span>
                                    <span v-else>
                                        <span class="amt" v-if="c.integral > 0"><em class="amtj">+{{c.integral}}</em>积分</span>
                                        <span class="amt" v-else-if="c.integral === 0"><em>{{c.integral}}</em>积分</span>
                                        <span class="amt" v-else><em class="amtz">{{c.integral}}</em>积分</span>
                                    </span>
                                </div>
                                <div v-if="c.recommend" class="desc">
                                    <span>{{c.recommend.shangpan}} vs {{c.recommend.xiapan}}</span>
                                </div>
                                <div v-else>
                                    <div v-if="c.desc" class="desc" v-html="c.desc"></div>
                                </div>
                            </div>
                        </van-cell>
                        <template #right>
                            <van-button square type="danger" @click="delscr(i)" text="删除" />
                        </template>
                    </van-swipe-cell>
                </li>
            </ul>
        </van-list>
        </van-pull-refresh>
        <div v-if="list.length === 0 && init === false"  class="list-none">没有记录~</div>
</div>
<van-popup position="bottom" v-model="typepicker">
    <van-picker
            title="标题"
            show-toolbar
            :columns="type_list"
            @confirm="onTypeConfirm"
            @cancel="onTypeCancel"
    />
</van-popup>

        <van-popup position="bottom" v-model="datepicker">
        <van-datetime-picker
                v-model="currentDate"
                type="year-month"
                title="选择年月"
                :min-date="minDate"
                :max-date="maxDate"
                :formatter="formatter"
                @cancel="onDateCancel"
                @confirm="onDateConfirm"
        />
        </van-popup>

    </div>
</template>

<script>
    export default {
        name: "cost",
        data() {
            return {
                showPopup: false,
                list_count: 0,
                list: [],
                init: true,
                loadmore: false, //是否在加载更多，当滚动到底部时自动设置为true(不需手动设置)，每次加载完都得手动设置为false，
                loadmore_error: false,
                finished: false, //是否加载完毕
                isloading: false, //是否正在读取
                error: false,     //是否读取错误，有错误自动设置为true，点击又重新读取更多（自动设置为false）
                refreshing: false,  //是否在下拉刷新
                pageIndex: 1,
                typepicker: false,
                type: 0,  //数据类型 0所有
                type_list: ['全部', '充值', '订阅', '赠送', '签到'],
                type_text: '全部',
                date_text: '选择日期',
                date_value: '',
                currentDate: new Date(),
                datepicker: false,
                minDate: new Date(2020, 0, 1),
                maxDate: new Date(),
                formPath: ''
            }
        },
        activated() {
            //this.getList(1)
            this.onRefresh(1)
        },
        methods: {
            loadMore() {
                let that = this
                if(!that.loadmore_error)
                    that.pageIndex++
                that.getList()
            },
            onRefresh(t=0){
                let that = this
                //that.list_count = 0
                that.pageIndex = 1
                that.finished = false
                that.loadmore = false
                that.error = false
                if(t===0)
                    that.refreshing = true
                that.getList(t)
            },
            getList(t=0){
                let that = this
                that.loadmore_error = false
                that.$post(that.$api.mycost, {page: that.pageIndex, type: that.type, time: that.date_value})
                    .then(function (res) {
                    let tmplist = res.data.cost_list
                    that.list_count = res.data.count
                    if(tmplist.length>0) {
                        if(t===1 || that.refreshing)
                            that.list = tmplist  //如果是下拉刷新则列表重置
                        else
                            that.list = that.list.concat(tmplist)
                        if (res.data.end)
                            that.finished = true
                    }
                    else{
                        if(that.refreshing)
                            that.list = []
                        that.finished = true
                    }
                }).catch(function () {
                    that.$toast.fail('读取失败')
                    that.error = true
                    that.loadmore_error = true
                }).finally(function () {
                    that.init = false
                    that.loadmore = false
                    that.isloading = false
                    that.refreshing = false
                })
            },
            delscr(index){
                let that = this
                that.$dialog.confirm({
                    title: '确认',
                    message: '确定删除该条记录？',
                }).then( () => {
                    let id = that.list[index].id
                    that.$toast.loading({message: '处理中', forbidClick: true, duration: 0})
                    that.$post(that.$api.mycost_del, {sid: id})
                        .then(function (res) {
                            if(res.code === 1){
                                that.list.splice(index,1)
                                that.list_count -= 1
                                that.$toast.success(res.msg)
                            }
                            else{
                                that.$toast.fail(res.msg)
                            }
                        }).catch(function () {
                        that.$toast.fail('fail')
                    })
                })
            },
            showDatePicker(){
                this.datepicker = true
            },
            onDateConfirm(value){
                let y = value.getFullYear(), m = value.getMonth() + 1
                this.date_text =  y+'年'+m+'月'
                this.date_value = y+'-'+m
                this.datepicker = false
                this.onRefresh()
            },
            onDateCancel(){
                this.datepicker = false
            },

            showTypePicker(){
                this.typepicker = true
            },
            onTypeConfirm(value, index){
                this.type = index
                this.type_text = this.type_list[index]
                this.typepicker = false
                this.onRefresh()
            },
            onTypeCancel(){
                this.typepicker = false
            },
            formatter(type, val) {
                if (type === 'month') {
                    return `${val}月`;
                } else if (type === 'day') {
                    return `${val}日`;
                }
                return val;
            },
            goBack(){
                //let that = this
                this.$router.push({
                    path: "/my" //that.formPath === '' ?'/':that.formPath
                })
            }
        },
        beforeRouteEnter(to, form, next){
            next(vm => {
                vm.formPath = form.path
            })
        }
    }
</script>

<style scoped>
</style>