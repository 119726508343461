import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Vant from 'vant'
import {post, get, api_url, get_config_text} from './utils/http.js'
import {formatTime} from "./utils/helper"
import 'vant/lib/index.css'
import "./assets/css/style.css"

Vue.config.productionTip = false
Vue.prototype.$post = post
Vue.prototype.$get = get
Vue.prototype.$api = api_url()
Vue.prototype.formatTime = formatTime
Vue.prototype.$get_config_text = get_config_text()
Vue.use(Vant)
new Vue({
  router,
  store,
  render: h => h(App),
  //-------------记录滚动条位置 start
  data: {
    timerId: ''
  },
  watch: {
    '$route': {
      handler () {
        // console.log('aaaaa')
        document.documentElement.scrollTop = this.$route.meta.y
      }
    }
  },
  mounted(){
    // console.log(this.justifyPos)
    window.addEventListener('scroll', this.justifyPos)
  },
  methods: {
    justifyPos () {
      if (this.timerId) clearTimeout(this.timerId)
      this.timerId = setTimeout(() => {
        // console.log(window.pageYOffset)
        this.$route.meta.y = window.pageYOffset
      }, 30)
    }
  },
  //-----------------记录滚动条位置 end

}).$mount('#app')
//npm run build
