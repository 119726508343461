import axios from "axios";
import qs from 'qs'
import $store from "../store"

let baseUrl = 'http://api.ac280.com'
// let baseUrl = 'http://127.0.0.1:8840'
let baseAPIUrl = baseUrl + '/api/v1/'
// let baseAPIUrl = baseUrl + '/api/v1/'
axios.defaults.timeout = 50000
axios.defaults.baseURL = baseAPIUrl
axios.defaults.withCredentials = false

//axios.defaults.headers.get['Content-Type'] = 'application/x-www-form-urlencoded'  //配置请求头数据格式
//axios.defaults.headers.post['Content-Type'] = 'application/json'  //配置请求头数据格式
//axios.defaults.headers['token'] = $store.state.token

/*
axios.interceptors.response.use(response =>{
    if(response.data.code = 2){
        //router.push()
    }
})
*/
export function get_config_text(){
    return {
        title:  ' | CK推球网'
    }
}

export function api_url(){
    return {
        imgBaseUrl: baseUrl,
        home: 'home',
        recommend_list: 'recommend/list',
        recommend_more: 'recommend/more',
        recommend_view: 'recommend/view',
        login: 'authen/login',
        register: 'authen/register',

        match_list: 'match/list',
        rank_list: 'rank/list',
        member_list: 'member/list',
        member_info: 'member/info',
        // member_recommend: 'member/recommend',
        member_rate: 'member/rate',

        news_list: 'news/list',
        news_info: 'news/detail',

        sign: 'common/sign',
        match_publish: 'common/match_publish',
        match_get: 'common/match_get',
        recommend_subscribe: 'common/subscribe',
        getnotice: 'common/getnotice',
        addfollow: 'common/addfollow',
        unaddfollow: 'common/unaddfollow',

        memberinfo: 'common/member/info',
        update_info: 'common/member/update_info',
        update_pwd: 'common/member/update_pwd',
        update_portrait: 'common/member/update_portrait',
        mysubscribe: 'common/member/subscribe',
        mysubscribe_del: 'common/member/subscribe-delete',
        myrecommend: 'common/member/recommend',
        myrecommend_user: 'common/member/recommend/subscribe_list',
        myrecommend_del: 'common/member/recommend-delete',
        mycost: 'common/member/cost',
        myfollow: 'common/member/follow',
        mycost_del: 'common/member/cost-delete',
        mywithdraw: 'common/member/withdraw',
        mywithdraw_list: 'common/member/withdraw_list',
        mywithdraw_submit: 'common/member/withdraw_submit',
        myrechange_submit: 'common/member/rechange_submit',
        mynotice: 'common/member/notice',
    }
}
/**
 * post方法，对应post请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function post(url, params = {}){
    let that = this
    return new Promise((resolve, reject) => {
        let token = that.$store.state.token
        // if(token === null || token === '' || token === undefined){
        //     that.$store.commit('SHOW_LOGINPOP')
        //     return
        // }
        axios.defaults.headers['token'] = token  //token放在此处意味着可以实时读取最新的
        axios.post(url, qs.stringify(params)).then(response => {
            // console.log(response.data)
            // console.log(that.$router)
            if(response.data.code === 1002){
                that.$store.commit('SHOW_LOGINPOP')
            }
            // else
                resolve(response.data)
        }, err=>{
            that.$toast.fail('request fail~')
            reject(err)
        })
    })
}
/**
 * get方法，对应get请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function get(url, params = {}){
    return new Promise((resolve, reject) => {
        axios.defaults.headers['token'] = $store.state.token  //token放在此处意味着可以实时读取最新的
        axios.get(url, {params:params}).then(response => {
            resolve(response.data)
        }, err=>{
            reject(err)
        })
    })
}
