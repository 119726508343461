<template>
    <div>
        <div id="header" class="fxwidth">
            <div class="hdtitle">排行榜</div>
<!--            <div class="hdbtn" @click="showPopupbox">榜单规则<van-icon name="question-o"></van-icon> </div>-->
        </div>
        <div class="headerhd headerhd_lg"></div>

<!--        <div class="fxwidth navtab">-->
<!--            <span v-for="(item, i) in tabList" :key="i"-->
<!--                  :class="{curr:tabIndex===i}"-->
<!--                  @click="changeTab(i)">{{item}}<i v-if="i==0"></i></span>-->
<!--        </div>-->
        <div class="fxwidth dropmenutab">
        <van-dropdown-menu active-color="#f90">
            <van-dropdown-item v-model="ball_val" :options="ball_list" @change="changeBall"/>
            <van-dropdown-item v-model="type_val" :options="type_list" @change="changeBall"/>
        </van-dropdown-menu>
        </div>

    <div>
        <div class="skeletonbx" v-if="init === true">
            <van-skeleton row="5"></van-skeleton>
        </div>
        <div v-if="list.length === 0 && init === false"  class="list-none">没有数据~</div>
        <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
            <van-list v-model="loadmore"
                      :immediate-check="false"
                      :finished="finished"
                      :finished-text="list.length>0?'没有了':''"
                      :error.sync="error"
                      :error-text="list.length>0?'读取失败':''"
                      @load="loadMore">
            <ul class="ranklist">
            <li v-for="(c,i) in list" :key="i">
                <div class="lfcon">
                <div :class="i<3?'num numc':'num'">{{i+1}}</div>
                <div class="name">
                    <router-link :to="'/member/'+c.user.id">
                        <div class="img">
                            <img :src="c.user.portrait" v-if="c.user.portrait"/>
                            <img :src="defimg" v-else/>
                        </div>
                        <div class="nick">{{c.user.name}}</div>
                    </router-link>
                </div>
                </div>
                <div class="linebx">
                    <div class="linecn">
                        <span class="sh"><em>{{c.sheng}}</em>红</span>
                        <span class="pi"><em>{{c.ping}}</em>和</span>
                        <span class="fu"><em>{{c.fu}}</em>黑</span>
                        <span class="sl"><i>{{c.sl}}%</i>胜率</span>
                    </div>
                    <div class="linect" :ref="i===0?'line':''"><div class="linecor" :style="c.lineW"></div></div>
                </div>
            </li>
        </ul>
            </van-list>
        </van-pull-refresh>
    </div>
        <van-popup position="bottom" v-model="showPopup" :duration=".3">
            <div class="popbox">
                <div class="popboxtitle">上榜规则</div>
                <div class="popboxcon rankpopcon">
                    <p class="t">满足以下条件即可上榜</p>
                    <p>1. 推介总数大于5场</p>
                    <p>2. 推介胜率大于40%</p>
                    <p>...</p>
                </div>
            </div>
        </van-popup>

    </div>
</template>

<script>
    import defPortrait from "../assets/images/user.png"
    export default {
        name: "rank",
        data(){
          return {
              ball_val: 1,
              type_val: 7,
              ball_list: [
                  {text: '足球', value: 1},
                  {text: '篮球', value: 2},
              ],
              type_list: [
                {text: '近7场', value: 7},
                {text: '近15场', value: 8},
                {text: '近30场', value: 9},
                {text: '近7天', value: 4},
                {text: '近15天', value: 15},
                {text: '近30天', value: 5},
                {text: '本月', value: 1},
                {text: '上月', value: 2},
                {text: '本周', value: 12},
                {text: '上周', value: 13}
              ],

              list: [],
              init: true,
              loadmore: false, //是否在加载更多，当滚动到底部时自动设置为true(不需手动设置)，每次加载完都得手动设置为false，
              loadmore_error: false,
              finished: false, //是否加载完毕
              error: false,     //是否读取错误，有错误自动设置为true，点击又重新读取更多（自动设置为false）
              refreshing: false,  //是否在下拉刷新
              pageIndex: 1,
              defimg: defPortrait,
              showPopup: false,
          }
        },
        created() {
            let that = this
            that.getList()
        },
        methods: {
            changeBall(){
                this.list = []
                this.pageIndex = 1
                this.init = true
                this.error = false
                this.finished = false
                this.loadmore = false
                this.getList()
            },
            showPopupbox(){
              this.showPopup = true
            },
            loadMore() {
                let that = this
                if(!that.loadmore_error)
                    that.pageIndex++
                that.getList()
            },
            onRefresh(){
                let that = this
                this.pageIndex = 1
                this.finished = false
                this.loadmore = false
                this.error = false
                that.getList()
            },
            getList(){
                let that = this
                that.loadmore_error = false
                this.$post(that.$api.rank_list, {page:that.pageIndex, ball:that.ball_val, type:that.type_val}).then(function (res) {
                    // console.log(res)
                    if(res.code === 1){

                        var tmplist = res.data.rank_list
                        if(tmplist.length>0) {
                            tmplist.forEach(function (i) {
                                if (i.user.portrait)
                                    i.user.portrait = that.$api.imgBaseUrl + i.user.portrait
                            })
                            if (that.refreshing)
                                that.list = tmplist  //如果是下拉刷新则列表重置
                            else
                                that.list = that.list.concat(tmplist)
                            that.getlinewidth()
                        }
                        else{
                            that.finished = true
                        }
                    }
                    else{
                        that.finished = true
                    }
                }).catch(function () {
                    that.$toast.fail('读取失败')
                    that.error = true
                    that.loadmore_error = true
                }).finally(function () {
                    that.init = false
                    that.loadmore = false
                    that.refreshing = false
                })
            },
            getlinewidth(){
                /**
                 * 设置胜率进度条长度
                 * @type {default.methods}
                 */
                let that = this
                that.$nextTick(() => {
                    let line = that.$refs.line
                    let width = 0
                    line.forEach((item) => {
                        width = item.offsetWidth //clientWidth
                    })
                    that.line_base_width = width
                    //更新胜率滚动条长度
                    that.list.forEach(function (i) {
                        //console.log(i)
                        let w = i.sl/100 * width
                        if(i.sl<60)
                            i.lineW = 'width:'+ w +'px;background-color:#ff6600;'
                        else
                            i.lineW = 'width:'+ w +'px'
                    })
                })
            }
        }
    }
</script>

<style scoped>

</style>