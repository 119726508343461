<template>
    <div>
        <div id="header" class="fxwidth">
            <div class="hdtitle">我的资料</div>
            <div class="hdback" @click="goBack"></div>
<!--            <div class="hdbtn hdedit" v-if="isedit===false" @click="toEdit"></div>-->
        </div>
        <div class="headerhd"></div>

        <div class="memberinfo">
            <div class="title"><span>基本信息</span><i></i></div>
            <div class="img">
                <van-uploader :after-read="afterRead" :max-size="1024 * 1024 * 5" @oversize="onOversize">
                    <img :src="member.portrait">
                    <span class="txt" v-if="isupload===false">修改头像</span>
                </van-uploader>
                <div class="imgupload" v-if="isupload"><van-loading vertical size="24"/></div>
            </div>
            <div class="item">
                <div class="name">账号</div>
                <div class="con">{{member.name_zs}}</div>
            </div>
            <div class="item">
                <div class="name">昵称</div>
                <div class="con" v-if="isedit">
                    <input type="text" v-model="nick" ref="refnick" placeholder="20个字符以内"/>
                    <button class="btnsp btnsp-cl" type="button" @click="toCancel">取消</button>
                    <button class="btnsp btnsp-sa" type="button" @click="toSave">保存</button>
                </div>
                <div class="con" v-else>{{member.nick}}
                    <button class="btnsp btnsp-ed" v-if="isedit===false" @click="toEdit">修改</button>
                </div>
            </div>
            <div class="item">
                <div class="name">金豆</div>
                <div class="con gold">{{member.gold}}<router-link to="tixian" class="btnsp btnsp-tx">提现</router-link></div>
            </div>
            <div class="item">
                <div class="name">积分</div>
                <div class="con">{{member.integral}}<router-link to="tixian?t=2" class="btnsp">兑换</router-link></div>
            </div>
            <div class="item">
                <div class="name">注册</div>
                <div class="con tme">{{member.create}}</div>
            </div>
            <div class="item">
                <div class="name">简介</div>
<!--                <div class="con" v-if="isedit"><textarea v-model="intro" placeholder="200个字符以内"></textarea></div>-->
<!--                <div class="con" v-else>{{member.intro}}</div>-->
                <div class="con">{{member.intro}}</div>
            </div>
<!--            <div class="item btn" v-if="isedit">-->
<!--                <button class="cancel" type="button" @click="toCancel">取消</button>-->
<!--                <button class="save" type="button" @click="toSave">保存</button>-->
<!--            </div>-->
            <div class="title title2"><span>账号安全</span><i></i></div>
            <div class="item" v-if="ischangePwd===false">
                <div class="name">密码</div>
                <div class="con">******
                    <button class="btnsp btnsp-ed" @click="toEditPwd">修改</button>
                </div>
                <br/>
                <br/>
            </div>
            <div v-else>
                <div class="item pwd">
                    <div class="name">原密码</div>
                    <div class="con">
                        <input type="password" placeholder="请输入原密码" v-model="password1" ref="refpwd1" autocomplete="off"/>
                    </div>
                </div>
                <div class="item pwd">
                    <div class="name">新密码</div>
                    <div class="con">
                        <input type="password" placeholder="请输入新密码" v-model="password2" ref="refpwd2" autocomplete="off"/>
                    </div>
                </div>
                <div class="item pwd">
                    <div class="name">确认密码</div>
                    <div class="con">
                        <input type="password" placeholder="请输入新密码" v-model="password3" ref="refpwd3" autocomplete="off"/>
                    </div>
                </div>
                <div class="item btn">
                    <button class="save" type="button" @click="toSavePwd">保存</button>
                    <button class="cancel" type="button" @click="toCancelPwd">取消</button>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
    import defPortrait from "../../assets/images/user.png"
    export default {
        name: "info",
        data (){
            return {
                member: {},
                defimg: defPortrait,
                nick: '',
                intro: '',
                isedit: false,
                isupload: false,
                ischangePwd: false,
                formPath: '',
                password1: '',
                password2: '',
                password3: '',
            }
        },
        activated() {
            let that = this
            this.$post(that.$api.memberinfo).then(function (res) {
                if (res.code === 1){
                    let data = res.data.member
                    if(data.portrait === null)
                        data.portrait = defPortrait
                    else
                        data.portrait = that.$api.imgBaseUrl + data.portrait
                    that.member = data
                    that.nick = data.nick
                    that.intro = data.intro
                }
            })
        },
        methods: {
            afterRead(file){
                // console.log(file)
                let that = this
                that.isupload = true
                that.member.portrait = file.content
                that.$post(that.$api.update_portrait, {img: file.content}).then(function (res) {
                    if(res.code === 1){
                        that.$toast.success(res.msg)
                    }
                    else{
                        that.$toast.fail(res.msg)
                    }
                }).catch(function () {
                    that.$toast.fail('fail')
                }).finally(function () {
                    that.isupload = false
                })
            },
            onOversize() {
                this.$toast.fail('文件大小不能超过 2M');
            },
            toEditPwd(){
                this.ischangePwd = true
            },
            toCancelPwd(){
                this.ischangePwd = false
            },
            toSavePwd(){
                let that = this
                if(that.password1 === ''){
                    this.$toast('请输入原密码')
                    this.$nextTick(() =>{
                        this.$refs.refpwd1.focus()
                    })
                    return
                }
                if(that.password2 === ''){
                    this.$toast('请输入新密码')
                    this.$nextTick(() =>{
                        this.$refs.refpwd2.focus()
                    })
                    return
                }
                if(that.password3 === ''){
                    this.$toast('请确认新密码')
                    this.$nextTick(() =>{
                        this.$refs.refpwd3.focus()
                    })
                    return
                }
                that.$toast.loading({duration: 0})
                that.$post(that.$api.update_pwd, {pwd1: that.password1, pwd2: that.password2, pwd3: that.password3}).then(function (res) { //, intro: that.intro
                    if(res.code === 1){
                        that.$toast.success(res.msg)
                        that.ischangePwd = false
                    }
                    else{
                        that.$toast.fail(res.msg)
                    }
                }).catch(function () {
                    that.$toast.fail('fail')
                }).finally(function () {
                    that.isupload = false
                })

            },
            toEdit(){
                this.isedit = true
                this.$nextTick(() =>{
                    this.$refs.refnick.focus()
                })
            },
            toCancel(){
                this.isedit = false
            },
            toSave(){
                if(this.nick === this.member.nick){// && this.intro === this.member.intro
                    //this.$toast.success()
                    this.isedit = false
                    return
                }
                if(this.nick === ''){
                    this.$toast('请填写昵称')
                    return
                }
                if(this.nick.length > 20){
                    this.$toast('昵称20字符以内~')
                    return
                }
                // if(this.intro.length > 200){
                //     this.$toast('简介200字符以内~')
                //     return
                // }
                let that = this
                that.$toast.loading({duration: 0})
                that.$post(that.$api.update_info, {nick: that.nick}).then(function (res) { //, intro: that.intro
                    if(res.code === 1){
                        that.$toast.success(res.msg)
                        that.isedit = false
                        that.member.nick = that.nick
                        that.member.intro = that.intro
                    }
                    else{
                        that.$toast.fail(res.msg)
                    }
                }).catch(function () {
                    that.$toast.fail('fail')
                }).finally(function () {
                    that.isupload = false
                })
            },
            goBack(){
                //let that = this
                this.$router.push({
                    path: "/my" //that.formPath === '' ?'/':that.formPath
                })
            }
        },
        beforeRouteEnter(to, form, next){
            next(vm => {
                vm.formPath = form.path
            })
        }
    }
</script>

<style scoped>

</style>