<template>
    <div class="login">
        <div id="header" class="fxwidth">
            <div class="hdback" @click="goBack"></div>
            <div class="hdtitle">注册会员</div>
        </div>
        <div class="headerhd"></div>

        <div class="item"><label>
            <input type="text" placeholder="账号" v-model="account" ref="refacc" autocomplete="off" autofocus="autofocus"/>
        </label>
            <p>字符长度4-20位，包括大小写字母、数字、下划线</p>
        </div>
        <div class="item"><label>
            <input type="password" placeholder="密码" v-model="password" ref="refpwd" autocomplete="off"/>
        </label>
            <p>密码长度6-20位</p>
        </div>
        <div class="item"><label>
            <input type="password" placeholder="确认密码" v-model="password2" ref="refpwd2" autocomplete="off"/>
        </label></div>
        <div class="item"><label>
            <input type="number" placeholder="验证码" v-model="yzmcode" ref="refcode" autocomplete="off"/>
        </label>
            <YZM @getIdentifyCode="valcode" :changeCode="changeCode" class="yzm" />
        </div>
<!--        <div class="code">-->
<!--            <JcRange status="jcRange_status" :successFun="jcRangeSuccess"></JcRange>-->
<!--        </div>-->
        <div class="btn">
            <button type="button" @click="toRegister" class="btnrg">注册</button>
        </div>
        <div class="act">
            已有账号，<router-link to="/login">直接登录</router-link>
        </div>
    </div>
</template>

<script>
    // import JcRange from "./public/JcRange";
    import YZM from "../components/public/yzcode";
    export default {
        name: "register",
        components: {
            // JcRange,
            YZM
        },
        data() {
            return {
                account: '',
                password: '',
                password2: '',
                formPath: '',
                yzm: '',
                yzmcode: '',
                changeCode: 0,
                // jcRange_status: false,
            }
        },
        methods: {
            // jcRangeSuccess: function(){
            //     this.jcRange_status = true
            // },
            valcode(data){
                this.yzm = data
            },
            toRegister () {
                let that = this
                if(that.account === ''){
                    this.$toast('请输入账号')
                    this.$nextTick(() =>{
                        this.$refs.refacc.focus()
                    })
                    return
                }
                if(that.password === ''){
                    this.$toast('请输入密码')
                    this.$nextTick(() =>{
                        this.$refs.refpwd.focus()
                    })
                    return
                }
                if(that.password2 === ''){
                    this.$toast('请再次输入密码')
                    this.$nextTick(() =>{
                        this.$refs.refpwd2.focus()
                    })
                    return
                }
                if(that.password2 !== that.password){
                    this.$toast('两次密码不一致')
                    this.$nextTick(() =>{
                        this.$refs.refpwd2.focus()
                    })
                    return
                }
                if(that.yzmcode === ''){
                    that.$toast('请输入验证码')
                    that.$nextTick(() =>{
                        that.$refs.refcode.focus()
                    })
                    return
                }
                if(that.yzmcode !== that.yzm){
                    that.$toast('验证码不正确')
                    that.yzmcode = ''
                    that.changeCode = Math.random()
                    that.$nextTick(() =>{
                        that.$refs.refcode.focus()
                    })
                    return
                }
                // if(!that.jcRange_status){
                //     this.$toast('请拖动滑块验证')
                //     return
                // }
                that.$toast.loading({
                    message: '注册中',
                    duration: 0,
                    forbidClick: true
                })
                setTimeout(function () {
                    that.$post(that.$api.register,
                        {name: that.account, pwd: that.password, pwd2: that.password2}).then(function (res) {
                        //console.log(res)
                        if(res.code === 1) {
                            that.$toast.success(res.msg)
                            that.$router.replace({path: '/login'})
                        }
                        else{
                            that.$toast.fail(res.msg)
                        }
                    })
                }, 300)
            },
            goBack(){
                //let that = this
                this.$router.push({
                    path: '/my' //that.formPath
                })
            }
        },
        beforeRouteEnter(to, form, next){
            next(vm => {
                vm.formPath = form.path
            })
        }
    }
</script>

<style scoped>

</style>
