<template>
    <div>
        <div class="screenFull" v-if="inited">
            <div class="skeletonbx">
                <van-skeleton row="5"></van-skeleton></div>
        </div>

        <div v-if="match" class="matchinfobx">
        <div class="matchdtbx">
            <div class="hdback" @click="goBack"></div>
            <div class="matchtp">
                <span class="ss">{{match.match_type}}</span>
                <span class="time">{{match.match_time}}</span>
            </div>
            <div class="matchcn">
                <span class="sp">{{match.shangpan}}</span>
<!--                <i v-if="match.bifen">{{match.bifen}}</i>-->
                    <em>vs</em>
                <span class="xp">{{match.xiapan}}</span>
            </div>
            <div class="matchbf" v-if="match.bifen">
                <div class="bf bf1">{{match.bf1}}</div>
                <div class="bf bf2">{{match.bf2}}</div>
            </div>
            <div class="matchiftab">
                <span :class="tabIndex===1?'curr':''" @click="changeTab(1)">分析</span>
                <span :class="tabIndex===2?'curr':''" @click="changeTab(2)">方案({{list_size}})</span>
            </div>
        </div>

        </div>


<div class="wrap">

    <div id="panel1" v-if="tabIndex === 1">
        <div class="pannelmatch" v-if="match">
            <div class="matchpk">
                <div class="lf">
                    <div><span>主队</span><em>{{match.pk}}</em><span>客队</span></div>
                    <div><span>大球</span><em>{{match.dxp}}</em><span>小球</span></div>
                </div>
                <button @click="showPublishPopBox">我要发布</button>
            </div>
            <div>{{match.desc}}</div>
        </div>
        <div v-if="recommend" class="recmdetail">
            <div class="fxlab fxmy" v-if="recommend.mycommend">我的推介</div>
            <div class="fxlab fxcm" v-if="recommend.isconsume">已购</div>
            <div class="porta">
                <div class="img">
                    <router-link :to="'/member/'+recommend.user.id">
                        <img :src="recommend.user.portrait" v-if="recommend.user.portrait"/>
                        <img :src="defimg" v-else/>
                    </router-link>
                </div>
                <div class="name">
                    <router-link :to="'/member/'+recommend.user.id">{{recommend.user.name}}</router-link>
                    <div>{{recommend.create}}发布</div>
                </div>
                <div :if="recommend.user.winrate" class="sl"><span>{{recommend.user.winrate}}%</span>胜率</div>
            </div>
            <div class="con">
                <div class="desc" v-if="recommend.commend_desc">推荐分析：<span>{{recommend.commend_desc}}</span></div>
                <span class="rs rs1" v-if="recommend.result_id == 1">等待推荐结果</span>
                <span :class="'rs rs'+recommend.result_id" v-else>{{recommend.result}}</span>
            </div>
            <div class="fxwidth recmdetfxbm" v-if="tabIndex===1">
                <span v-if="recommend.showtype == 1" class="dai">待公开</span>
                <div v-else-if="recommend.showtype == 2" class="kck">
                    <span><em>{{recommend.gold}}</em>金豆查看<i></i></span>
                    <button @click="ding(recommend.uuid, recommend.gold)">支付查看</button>
                </div>
                <div v-else-if="recommend.showtype == 3 || recommend.showtype == 4" class="gk">
                    <span>{{recommend.commend}}<i></i></span>
                    <em></em>
                </div>
            </div>
        </div>
    </div>

        <div id="panel2" v-if="tabIndex === 2">
            <div v-if="list.length > 0">
        <van-list v-model="loading"
                  :immediate-check="false"
                  :finished="finished"
                  finished-text="没有更多内容了"
                  @load="onLoad">
            <ul class="tuijielist recmlist">
                <li v-for="(c,i) in list" :key="i">
                    <div class="porta">
                        <router-link :to="'/member/'+c.user.id">
                            <img :src="c.user.portrait" v-if="c.user.portrait"/>
                            <img :src="defimg" v-else/>
                            <span>{{c.user.name}}</span>
                        </router-link>
                        <em :if="c.user.winrate">{{c.user.winrate}}%胜率</em>
                    </div>
                    <div class="detc">
                        <span class="tm">{{c.create}}发布</span>
                        <span v-if="c.showtype == 1" class="dai">待公开</span>
                        <span v-else-if="c.showtype == 2" class="kck" @click="ding(c.uuid, c.gold)"><em>{{c.gold}}</em>金豆查看</span>
                        <span v-else-if="c.showtype == 3 || c.showtype == 4" class="gk"><em>荐</em>{{c.commend}}</span>
                        <span :class="'rs rs'+c.result_id">{{c.result}}</span>
                    </div>
                </li>
            </ul>
        </van-list>
            </div>
            <div v-else class="list-none">没有方案~</div>
        </div>
</div>

        <Publish v-if="showPublishPopup"></Publish>
    </div>
</template>

<script>
    import defPortrait from "../assets/images/user.png"
    import Publish from "./public/publish";
    export default {
        name: "recommend_view",
        components: {Publish},
        comments: {
            Publish
        },
        inject: ['reload'], //注入reload方法(在App.vue)
        data() {
            return {
                inited: true,
                match: null,
                tabIndex: 1,
                list: [],
                list_size: 0,
                recommend: null,
                loading: true,
                finished: false,
                isloading: false,
                defimg: defPortrait,
                rid: null,
                mid: null,

                showPublishPopup: false,
                selectMatchId: 0,


                formPath: '',
            }
        },

        created() {
            //let that = this
            // console.log('created')
            // let rid = this.$route.params.rid   //推介id
            // let mid = this.$route.params.mid  //赛事id
            // console.log(rid)
            // console.log(mid)
            //that.rid = rid
            //that.mid = mid
            //that.getList()
        },
        activated() {
            let that = this
            // console.log('activated')
            let rid = this.$route.params.rid   //推介id
            let mid = this.$route.params.mid  //赛事id
            that.rid = rid
            that.mid = mid
            // console.log(rid)
            // console.log(mid)
            that.finished = false
            that.list = []
            that.match = null
            that.recommend = null
            that.inited = true
            that.getList()
        },
        methods: {
            changeTab(i){
              this.tabIndex = i
            },
            onLoad() {
                let that = this
                setTimeout(function () {
                    //console.log(that.pageIndex)
                    that.pageIndex++
                    that.getList()
                }, 500)
            },
            getList(){
                let that = this
                // console.log(that.rid)
                // console.log(that.finished)
                // console.log(that.isloading)
                if(that.finished) return
                if(that.isloading) return
                that.isloading = true
                // console.log('start')
                that.$post(that.$api.recommend_view, {rid: that.rid, mid: that.mid}).then(function (res) {
                    // console.log(res)
                    that.isloading = false
                    that.loading = false

                    that.list_size = res.data.list_size
                    that.match = res.data.match
                    if(that.match){
                        document.title = that.match.shangpan + 'vs' + that.match.xiapan + that.$get_config_text.title
                    }
                    that.mid = res.data.match.id
                    if(res.data.recommend) {
                        that.recommend = res.data.recommend
                        if(that.recommend.user.portrait==null) {
                            that.recommend.user.portrait = ''
                        }else{
                            that.recommend.user.portrait = that.$api.imgBaseUrl + that.recommend.user.portrait
                        }
                    }
                    //console.log(that.recommend)

                    let list = res.data.recommend_list
                    if(list.length>0) {
                        list.forEach(function (i) {
                            if(i.user.portrait)
                                i.user.portrait = that.$api.imgBaseUrl + i.user.portrait
                        })
                        that.list = that.list.concat(list)
                        if (list.length < 20)
                            that.finished = true
                    }
                    else{
                        that.finished = true
                    }
                }).catch(function () {
                    //console.log(res)
                    that.$toast.fail('读取失败')
                    that.finished = true
                }).finally(function () {
                    that.inited = false
                })
            },
            ding(id, gold){
                let that = this
                that.$dialog.confirm({
                    title: '确认',
                    message: '确定订阅本场推介, 消费'+gold+'金豆？',
                }).then( () => {
                    that.$toast.loading()
                    that.$post(that.$api.recommend_subscribe, {rid: id}).then(function (res) {
                        //console.log(res)
                        if(res.code === 1){
                            that.$toast.success(res.msg)
                            //that.reload()  调用App.vue中reload
                            that.recommend.showtype = 3
                            that.recommend.isconsume = true
                            that.recommend.commend = res.data.commend
                        }
                        else{
                            that.$toast.fail(res.msg)
                        }
                    })
                }).catch( () => {
                    //no fun
                })
            },

            showPublishPopBox(){
                let token = this.$store.state.token, that = this
                if(token === null || token === '' || token === undefined){
                    //this.$toast({message: '请登录'})
                    that.$store.commit('SHOW_LOGINPOP')
                    return
                }
                that.showPublishPopup = true
                that.selectMatchId = that.mid
            },
            publishSuccess(){
                //发布推介成功
                let that = this
                that.showPublishPopup = false
            },
            closePublishPopBox(){
                //关闭发布弹窗
                let that = this
                that.showPublishPopup = false
            },
            goBack(){
                // this.$router.push({
                //     path: '/recommend'
                // })
                let that = this
                this.$router.push({
                    path: that.formPath === '' ?'/recommend':that.formPath
                    // path: '/'
                })
            }
        },
        beforeRouteEnter(to, form, next){
            next(vm => {
                vm.formPath = form.path
            })
        }
    }
</script>

<style scoped>
</style>
