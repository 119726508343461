<template>
    <div>
<!--        <div id="header" class="fxwidth">-->
<!--            <div class="hdback" @click="goBack"></div>-->
<!--            <div class="hdtitle" v-if="user">{{user.name}}</div>-->
<!--        </div>-->
<!--        <div class="headerhd"></div>-->
        <div v-if="user" class="fxwidth userfex">
            <div class="hdback" @click="goBack"></div>
        <div class="userbox" ref="userfexf">
            <div class="userboxcn">
                <div class="img">
                    <img :src="user.portrait" v-if="user.portrait">
                    <img :src="defimg" v-else>
                </div>
                <div class="cone">
                    <div class="name">{{user.name}}</div>
                    <div class="usertot" v-if="user">
                        <div class="item">
                            <em>{{user.fans_count}}</em>
                            <span>位粉丝</span>
                        </div>
                        <div class="item" v-if="user.recommend_count > 0">
                            <em>{{user.recommend_count}}</em>
                            <span>场推介</span>
                        </div>
                    </div>
                    <div class="tprel" v-if="user">
                        <span v-if="user.relation == 0" @click="addFollow(user.id)"><em>+</em>关注</span>
                        <span v-else-if="user.relation == 1" class="en" @click="unAddFollow(user.id)">已关注</span>
                        <span v-else-if="user.relation == 2" class="en" @click="unAddFollow(user.id)">互相关注</span>
                    </div>
                </div>
            </div>
            <div class="intro" v-if="user.intro">{{user.intro}}</div>
        </div>
        </div>
        <div v-if="user" class="userfexlg" :style="'height:'+hdFxHg+'px'"></div>

        <div class="skeletonbx" v-if="init === true">
            <van-skeleton title row="5"></van-skeleton>
        </div>
        <template v-else>

        <div class="list_count">
            TA的战绩
            <div class="fttabs">
                <div class="item">
                    <span :class="ftType===1?'curr':''" @click="getRate(1, ftBall)">按场</span>
                    <span :class="ftType===2?'curr':''" @click="getRate(2, ftBall)">按天</span>
                </div>
                <div class="item">
                    <span :class="ftBall===1?'curr':''" @click="getRate(ftType,1)">足球</span>
                    <span :class="ftBall===2?'curr':''" @click="getRate(ftType,2)">篮球</span>
                </div>
            </div>
        </div>
        <div class="rate_box">
            <ul class="ranklist" v-if="rate_list.length > 0">
                <li v-for="(c,i) in rate_list" :key="i">
                    <div class="title">
                        近{{c.name}}
                    </div>
                    <div class="linebx">
                        <div class="linecn">
                            <span class="sh"><em>{{c.sheng}}</em>红</span>
                            <span class="pi"><em>{{c.ping}}</em>和</span>
                            <span class="fu"><em>{{c.fu}}</em>黑</span>
                            <span class="sl"><i>{{c.sl}}%</i>胜率</span>
                        </div>
                        <div class="linect" :ref="i===0?'line':''"><div class="linecor" :style="c.lineW"></div></div>
                    </div>
                </li>
            </ul>
            <div v-else class="rate_none">无记录</div>

        </div>

        <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
        <van-list v-model="loadmore"
                  :immediate-check="false"
                  :finished="finished"
                  finished-text="没有了"
                  :error.sync="error"
                  :error-text="list.length>0?'读取失败':''"
                  @load="loadMore">
            <div class="list_count">
                TA的推介
                <div class="fttabs">
                    <div class="item">
                        <span :class="rcBall===1?'curr':''" @click="getRecommend(1)">足球</span>
                        <span :class="rcBall===2?'curr':''" @click="getRecommend(2)">篮球</span>
                    </div>
                </div>
            </div>
            <ul class="tuijielist memberinfolist" v-if="list.length > 0">
                <li v-for="(c,i) in list" :key="i" :class="'ball'+c.ball">
                    <router-link :to="'/recommend/'+c.uuid">
                        <div class="con">
                            <div class="conlf">
                                <div class="tmss">
                                    <span class="ss" :style="{background:c.color, color:'#fff'}">{{c.match_type}}</span>
                                    <span class="time">{{c.time}}</span>
                                </div>
                                <div class="duiz">
                                    <span>{{c.shangpan}}</span>
                                    <em v-if="c.bifen" class="bf">{{c.bifen}}</em>
                                    <em v-else class="vs">vs</em>
                                    <span>{{c.xiapan}}</span>
                                </div>
                            </div>
                            <span :class="'rs rs'+c.result_id">{{c.result}}</span>
                        </div>
                        <div class="desc" v-if="c.commend_desc">推介分析：{{c.commend_desc}}</div>
                        <div class="detc">
                            <span class="tm">{{c.create}}发布</span>
                            <span v-if="c.showtype == 1" class="dai">待公开</span>
                            <span v-else-if="c.showtype == 2" class="kck"><em>{{c.gold}}</em>金豆查看</span>
                            <!--                        <span v-else-if="c.showtype == 3 || c.showtype == 4" class="gk"><em>荐</em>{{c.commend}}</span>-->
                            <span v-else-if="c.showtype == 3" class="gk">查看详情 &gt;</span>
                        </div>
                    </router-link>
                </li>
            </ul>
        </van-list>
        </van-pull-refresh>

        </template>
    </div>
</template>

<script>
    import defPortrait from "../assets/images/user.png"
    export default {
        name: "member",
        data() {
            return {
                uid: null,
                user: null,
                action: 1,
                list: [],
                recommend_count: 0,
                init: true,
                loadmore: false, //是否在加载更多，当滚动到底部时自动设置为true(不需手动设置)，每次加载完都得手动设置为false，
                loadmore_error: false,
                finished: false, //是否加载完毕
                isloading: false, //是否正在读取
                error: false,     //是否读取错误，有错误自动设置为true，点击又重新读取更多（自动设置为false）
                refreshing: false,  //是否在下拉刷新
                pageIndex: 1,
                defimg: defPortrait,
                formPath: '',
                rate_list: [],
                hdFxHg: 140,

                ftType: 1,
                ftBall: 1,

                rcBall: 0,
            }
        },
        activated() {
            let uid = this.$route.params.uid   //uid
            this.uid = uid
            this.list = []
            this.rate_list = []
            this.user = null
            this.action = 1
            this.rcBall = 0
            //this.$toast.loading()
            this.onRefresh()
            //document.addEventListener('scroll', this.handleScroll, true)
        },
        // mounted: {
        // },
        methods: {
            // handleScroll(){
            //     let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
            //     console.log(scrollTop)
            //     // var a = document.getElementsByClassName('ted-header')[0].offsetHeight
            //     // console.log(window.pageYOffset,a)
            // },
            loadMore() {
                let that = this
                // if(that.loadmore) return
                if(!that.loadmore_error)
                    that.pageIndex++
                that.getList()
            },
            onRefresh(){
                let that = this
                this.pageIndex = 1
                this.finished = false
                this.loadmore = false
                this.error = false
                that.getList()
            },
            getList(t=0){
                let that = this
                that.loadmore_error = false
                that.$post(that.$api.member_info,{page: that.pageIndex, uid: that.uid, action: that.action, ball: that.rcBall}).then(function (res) {
                    // console.log(res)
                    if(res.code === 1) {
                        if(res.data.user) {
                            let usertmp = res.data.user
                            if (usertmp.portrait)
                                usertmp.portrait = that.$api.imgBaseUrl + usertmp.portrait
                            that.user = usertmp
                            // setTimeout(()=>{
                                that.$nextTick(() => {
                                    that.hdFxHg = that.$refs.userfexf.offsetHeight
                                    // console.log(that.hdFxHg)
                                })
                            // }, 100)
                            document.title = usertmp.name + that.$get_config_text.title
                        }
                        if(res.data.rate_ball)
                            that.ftBall = res.data.rate_ball
                        if(res.data.rate_list && res.data.rate_list.length>0) {
                            that.rate_list = res.data.rate_list
                            that.getlinewidth()
                        }
                        if(res.data.recommend_count)
                            that.recommend_count = res.data.recommend_count
                        if(res.data.recommend_list) {
                            let tmplist = res.data.recommend_list
                            if (tmplist.length > 0) {
                                tmplist.forEach(function (i) {
                                    if (i.portrait)
                                        i.portrait = that.$api.imgBaseUrl + i.portrait
                                })
                                if (that.refreshing || t==1)
                                    that.list = tmplist  //如果是下拉刷新则列表重置
                                else
                                    that.list = that.list.concat(tmplist)
                                if (res.data.end)
                                    that.finished = true
                            } else {
                                that.finished = true
                            }
                        }
                    }
                    else{
                        that.$toast.fail('读取失败')
                    }
                })
                //.catch(function () {
                //     that.$toast.fail('读取失败')
                //     console.log('catch')
                //     that.error = true
                //     that.loadmore_error = true
                // })
                .finally(function () {
                    // console.log('finally')
                    that.action = 2
                    that.init = false
                    that.loadmore = false
                    that.isloading = false
                    that.refreshing = false
                })
            },
            getRecommend(t=0){
                let that = this
                that.rcBall = t
                that.pageIndex = 1
                that.list = []
                that.getList(1)
            },
            getRate(t=1, b=1){
                let that = this
                that.ftBall = b
                that.ftType = t
                that.$post(that.$api.member_rate,{uid: that.uid, ball: that.ftBall, type: that.ftType}).then(function (res) {
                    // console.log(res)
                    if(res.code === 1) {
                        // if(res.data.rate_list.length>0) {
                            that.rate_list = res.data.rate_list
                            that.getlinewidth()
                        // }
                    }
                })
            },

            addFollow(id){
                let that = this
                that.$toast.loading()
                that.$post(that.$api.addfollow, {mid: id}).then(function (res) {
                    if(res.code === 1){
                        that.$toast.success(res.msg)
                        that.user.relation = res.data.relation
                        that.user.fans_count += 1
                    }
                    else{
                        that.$toast(res.msg)
                    }
                })
            },
            unAddFollow(id){
                let that = this
                that.$toast.loading()
                that.$post(that.$api.unaddfollow, {mid: id}).then(function (res) {
                    if(res.code === 1){
                        that.$toast.success(res.msg)
                        that.user.relation = res.data.relation
                        that.user.fans_count -= 1
                    }
                    else{
                        that.$toast(res.msg)
                    }
                })
            },
            goBack(){
                let that = this
                this.$router.push({
                    path: that.formPath === '' ?'/':that.formPath
                    // path: '/'
                })
            },
            getlinewidth(){
                /**
                 * 设置胜率进度条长度
                 * @type {default.methods}
                 */
                let that = this
                that.$nextTick(() => {
                    let line = that.$refs.line
                    let width = 0
                    line.forEach((item) => {
                        width = item.offsetWidth //clientWidth
                    })
                    that.line_base_width = width
                    //更新胜率滚动条长度
                    let tmplist = that.rate_list
                    tmplist.forEach(function (i) {
                        let w = i.sl/100 * width
                        if(i.sl < 60)
                            i.lineW = 'width:'+ w +'px;background-color:#ff6600;'
                        else
                            i.lineW = 'width:'+ w +'px'
                    })
                    that.rate_list = tmplist
                })
            }
        },
        beforeRouteEnter(to, form, next){
            next(vm => {
                vm.formPath = form.path
            })
        }
    }
</script>

<style scoped>
</style>
